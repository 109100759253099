import React, { useContext } from "react";
import { isAdmin } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import AddIcon from "@mui/icons-material/Add";
import { Context } from "../../../App";

export default function AddOrAllSection(props) {
  const { type, link } = props;
  const isAdminUser = isAdmin();
  const { showModal } = useContext(Context);

  const scrolltop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const setHeader = () => {
    if (type == "product") return "View Our Smart City Solutions";
    if (type == "partner") return "Meet Our Partners";
    if (type == "news") return "See Our News";
    if (type == "blogs") return "See Our Blog";
    if (type == "feedback") return "What Clients Say";
  };

  const setParagraph = () => {
    if (type == "product")
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
    if (type == "partner")
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";

    if (type == "news")
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";

    if (type == "blogs")
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";

    if (type == "feedback")
      return "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor";
  };

  return (
    <>
      {/* <h2>{setHeader()}</h2> */}
      {/* <p>{setParagraph()}</p> */}
      <div
        className="menu-list"
        style={{
          display: "flex",
          justifyContent: isAdminUser ? "space-around" : "space-between",
          // width: "100px",
          // marginTop: "40px",
        }}
      >
        <Link
          to={`${process.env.PUBLIC_URL}/${link}`}
          onClick={scrolltop}
          className="eg-btn hover-btn sectoin-card-btn"
        >
          <span>
            {/* {type == "product" ? "View All Solutions" : "Explore more"} */}
            Explore more
            {isAdminUser ? (
              <ArrowRightAltIcon fontSize="small" />
            ) : (
              <img
                src={process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"}
                alt="images"
              />
            )}
          </span>
        </Link>

        {isAdminUser && (
          <Link
            // to={`${process.env.PUBLIC_URL}/service`}
            onClick={() => showModal(type)}
            className="eg-btn hover-btn sectoin-card-btn"
          >
            <span>
              Add {type == "product" ? "solution" : type}
              <AddIcon fontSize="small" />
            </span>
          </Link>
        )}
      </div>
    </>
  );
}
