import axios from "axios";

const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const getSolutionById = async (id) => {
  try {
    const res = await axios.get(`${endPoint}/solutions/${id}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const downloadSolutionDocument = async (url) => {
  try {
    const res = await fetch(`${endPoint}/download-static?url=${url}`);
    const data = await res?.blob();
    return data;
  } catch (err) {
    return null;
  }
};

export const getAllSolutions = async (limit) => {
  try {
    let limitURL = "";
    if (limit) limitURL = `?limit=${limit}`;
    const res = await axios.get(`${endPoint}/solutions${limitURL}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const addOrUpdateSolution = async (formData, id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    let res;
    if (id)
      res = await axios.put(`${endPoint}/solutions/${id}`, formData, auth);
    else res = await axios.post(`${endPoint}/solutions`, formData, auth);
    return res?.data;
  } catch (err) {
    // console.log(err);
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};

export const deleteSolution = async (id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.delete(`${endPoint}/solutions/${id}`, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
