import React, { useContext, useState, useEffect } from "react";
import ProductCard from "../products/ProductCard";
import AddOrAllSection from "./CommonUtils/AddOrAllSection";
import { Context } from "../../App";

function ProductArea({ data }) {
  const { reloadAnimation } = useContext(Context);

  const [isLoading, setLoading] = useState(true);

  useEffect(async () => {
    if (!isLoading) {
      reloadAnimation();
      return;
    }
    setLoading(false);
  }, [isLoading]);

  return (
    <>
      <div className="latest-work pt-120 pb-120">
        <div className="container">
          {/* <div className="row d-flex justify-content-lg-end justify-content-center"> */}
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-7 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Our Solutions</h2>
              </div>
            </div>
          </div>
          <div className="row g-4 d-flex justify-content-center ">
            {/* <div className="col-lg-5 col-md-8 col-sm-8 order-lg-1 order-0">
              <div
                className="section-card l-work-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
                style={{ padding: "23px 30px" }}
              >
                <AddOrAllSection link="solutions" type="product" />
              </div>
            </div> */}

            {data?.slice(0, 2).map((product, index) => {
              {
                /* if (index == 0) */
              }
              return (
                <ProductCard
                  data={product}
                  // lgWidth="7"
                  // mdWidth="12"
                  wowDelay={0.5 + 0.1 * index}
                />
              );
              {
                /* else
                return (
                  <ProductCard data={product} wowDelay={0.5 + 0.1 * index} />
                ); */
              }
            })}

            <div className="col-lg-4 col-md-6 col-sm-8 order-lg-1 order-2">
              <div
                className="section-card l-work-card wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
                style={{ padding: "23px 30px" }}
              >
                <AddOrAllSection link="solutions" type="product" />
              </div>
            </div>

            {data?.slice(2).map((product, index) => {
              return (
                <ProductCard data={product} wowDelay={0.5 + 0.1 * index} />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductArea;
