import React from "react";
import { Link } from "react-router-dom";
import { isAdmin } from "../../../../utils/helpers";
import ReadMoreBtn from "./ReadMoreBtn";
import EditDeleteBtn from "../CommonUtils/EditDeleteBtn";

const endPoint = process.env.REACT_APP_END_POINT;

function NewsCard(props) {
  const { showModal, data, link, type = "news" } = props;
  const isAdminUser = isAdmin();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const setDate = (date) => {
    return new Date(date).toLocaleString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <div className="eg-card-wrap wow animate fadeInDown" data-wow-duration="1.5s" data-wow-delay="0.5s">
      <div className="eg-card blog-item-1 d-flex justify-content-sm-start justify-content-center align-items-center flex-sm-nowrap flex-wrap">
        <div className="blog-img" style={{ maxHeight: "200px" }}>
          <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/${link}/${data?._id}`}>
            <img src={data?.wallImageURL ? endPoint + data?.wallImageURL : "images/icon/no_img.png"} alt="images" />
          </Link>
        </div>
        <div className="blog-content text-sm-start text-center">
          <h6>{setDate(data?.date)}</h6>
          <Link to={`${process.env.PUBLIC_URL}/${link}/${data?._id}`} onClick={scrollTop}>
            <h4>{data?.title}</h4>
          </Link>
          <div
            className="styled-text para"
            dangerouslySetInnerHTML={{
              __html: data?.mainSection.replace(/\n/g, "<br>").substring(0, 100) + " ...",
            }}
          />
          {/* <p className="para">{data?.mainSection.substring(0, 100) + " ..."}</p> */}
          <ReadMoreBtn link={link} id={data?._id} />
          <EditDeleteBtn type={type} color="white" data={data} />
        </div>
      </div>
    </div>
  );
}

export default NewsCard;
