import axios from "axios";
import React from "react";
import { Link } from "react-router-dom";
import { downloadSolutionDocument } from "../../../APIs/solutionAPIs";

const endPoint = process.env.REACT_APP_END_POINT;

export default function DocumentSection(props) {
  const { title, summary, documentURL } = props;
  const download = async (e) => {
    e.preventDefault();
    const blob = await downloadSolutionDocument(documentURL);
    if (blob) {
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${title}.pdf`);

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }
  };
  // const download = (e) => {
  //   e.preventDefault();
  //   downloadSolutionDocument(documentURL);
  // };
  return (
    <div
      className="section-card service-details-card wow animate fadeInDown"
      data-wow-duration="1.5s"
      data-wow-delay="0.5s"
    >
      <h2>{title}</h2>
      <p>{summary}</p>
      {documentURL && (
        <Link
          // to={"#"}
          class="eg-btn hover-btn sectoin-card-btn"
          onClick={(e) => download(e)}
        >
          <span>
            Download Document{" "}
            <img
              src={process.env.PUBLIC_URL + "/images/icon/arrow-servc.svg"}
              alt="images"
            />
          </span>
        </Link>
      )}
    </div>
  );
}
