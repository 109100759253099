import React, { useState } from "react";
import { Link } from "react-router-dom";
import { SRLWrapper } from "simple-react-lightbox";
import Sponsor from "../Sponsor";
import ExploreOn from "./ExploreOn";
import FooterFollowUs from "./FooterFollowUs";
import FooterInfo from "./FooterInfo";
import FooterLink from "./FooterLink";
import FooterProjects from "./FooterProjects";
import FooterSolutions from "./FooterSolutions";
import FooterProduct from "./FooterProduct";
function Footer() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-top-area">
            <div className="row d-flex justify-content-sm-start justify-content-center gy-5">
              <div className="col-lg-5 col-md-6 text-sm-start">
                <FooterInfo />
              </div>

              <div className="col-lg-7 col-md-6">
                <div className="footer-right-area">
                  <div className="row g-4">
                    <ExploreOn />
                    <FooterProduct />
                    {/* <FooterSolutions /> */}
                    {/* <FooterProjects /> */}
                    <FooterFollowUs />
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <div className="footer-bottom-area"></div> */}
        </div>
      </footer>

      <div className="scroll-btn show">
        <ScrollButton></ScrollButton>
      </div>
    </>
  );
}
const ScrollButton = () => {
  const [visible, setVisible] = useState(false);

  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 800) {
      setVisible(true);
    } else if (scrolled <= 100) {
      setVisible(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  window.addEventListener("scroll", toggleVisible);

  return (
    <i
      className="bx bxs-up-arrow-circle"
      onClick={scrollToTop}
      style={{ display: visible ? "inline" : "none" }}
    ></i>
  );
};

export default Footer;
