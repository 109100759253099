import React from "react";
import MainSection from "../../common/sections/MainSection";
import DocumentSection from "./DocumentSection";
import ClientFeedback from "./ClientFeedback";
import { Link } from "react-router-dom";
import Media from "../../common/sections/Media";
import Sections from "../../common/sections/Sections";
import ProductFeatures from "./ProductFeatures";

const endPoint = process.env.REACT_APP_END_POINT;

function ProductDetailsWrap({ data }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  const windowWidth = window.innerWidth;

  return (
    <>
      <div className="service-section2 pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center gy-5">
            <div className="col-lg-5 col-md-5 mb-30">
              <DocumentSection
                id={data?._id}
                title={data?.title}
                summary={data?.summary}
                documentURL={data?.documentURL}
              />

              <div
                className="right-sidebar-img wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.6s"
              >
                {data?.secondMediaURL && (
                  <Media mediaUrl={data?.secondMediaURL} />
                )}

                {data?.thirdMediaURL && (
                  <Media mediaUrl={data?.thirdMediaURL} />
                )}
              </div>

              {data?.clientFeedback && (
                <ClientFeedback clientFeedbackData={data?.clientFeedback} />
              )}
              {windowWidth > "767" ? (
                <div
                  className="hire-card wow animate fadeInDown"
                  data-wow-duration="1.5s"
                  data-wow-delay="0.5s"
                >
                  <h3>NEED A TAILORED SOLUTION?</h3>
                  <div className="eg-btn hire-btn hover-btn">
                    <span>
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/contact-us`}
                      >
                        Contact Us
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/images/icon/arrow-dark.svg"
                          }
                          alt="images"
                        />
                      </Link>
                    </span>
                  </div>
                </div>
              ) : null}
            </div>

            <div className="col-lg-7 col-md-7">
              <div className="section-title text-center">
                <h2>Solution Details</h2>
              </div>
              <div className="service-details-area">
                <div className="service-details-img">
                  <Media mediaUrl={data?.mainMediaURL} />
                </div>

                <div className="service-details-content">
                  <MainSection
                    title={data?.title}
                    text={data?.mainSection}
                    type="product"
                  />

                  <Sections sectionsData={data?.sections} type="product" />
                </div>

                {data?.features?.length !== 0 && (
                  <div className="work-process">
                    <h3>Solution Features</h3>
                    <ProductFeatures data={data?.features} />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductDetailsWrap;
