import React from "react";
import Media from "../../common/sections/Media";

export default function ClientFeedback({ clientFeedbackData }) {
  return (
    <div>
      <div
        className="client-feedback-area wow animate fadeInDown"
        data-wow-duration="1.5s"
        data-wow-delay="0.7s"
      >
        <h3>Client Feedback of This Solution :</h3>
        <div className="client-feedback-card">
          <div className="client-img">
            {clientFeedbackData?.imageURL ? (
              <Media mediaUrl={clientFeedbackData?.imageURL} />
            ) : (
              <i class="bi bi-person-circle" style={{ fontSize: 65 }}></i>
            )}
          </div>
          <div className="client-content">
            <h5>{clientFeedbackData?.name} </h5>
            <h6>{clientFeedbackData?.position} </h6>
            <p>{clientFeedbackData?.feedback}</p>
          </div>
        </div>
      </div>
    </div>
  );
}
