import axios from "axios";
const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const getProductById = async (id) => {
  try {
    const res = await axios.get(`${endPoint}/products/${id}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const downloadStatic = async (url) => {
  try {
    const res = await fetch(`${endPoint}/download-static?url=${url}`);
    const data = await res?.blob();
    return data;
  } catch (err) {
    return null;
  }
};

export const getAllProducts = async (limit, type) => {
  try {
    let limitURL, typeURL, andOp;
    limitURL = typeURL = andOp = "";
    if (limit) limitURL = `?limit=${limit}`;
    if (type) typeURL = `?type=${type}`;
    if (limit && type) andOp = "&";
    const url = `${endPoint}/products${limitURL}${andOp}${typeURL}`;
    const res = await axios.get(url);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const addOrUpdateProduct = async (formData, id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    let res;
    if (id) res = await axios.put(`${endPoint}/products/${id}`, formData, auth);
    else res = await axios.post(`${endPoint}/products`, formData, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};

export const deleteProduct = async (id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.delete(`${endPoint}/products/${id}`, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
