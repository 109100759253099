import React from "react";

export default function Spinner({ biggerHeight }) {
  return (
    <>
      <div
        class="d-flex justify-content-center text-center"
        style={{ height: biggerHeight ? "30rem" : "15rem" }}
      >
        <div
          class="spinner-border"
          role="status"
          style={{
            color: "#0079b3",
            margin: "auto",
            width: "4rem",
            height: "4rem",
          }}
        >
          <span class="sr-only"></span>
        </div>
      </div>
    </>
  );
}
