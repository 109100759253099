import React from "react";
import { useLocation } from "react-router-dom";
import RecentPostItem from "./RecentPostItem";
function RecentPostWidget({ data, setLoading }) {
  let location = useLocation();
  let counter = 0;
  return (
    <>
      <div className="widget">
        <h4 className="widget-title">Recent Post</h4>
        <ul className="m-0 p-0">
          {data?.map((item, index) => {
            if (!location?.pathname?.includes(item?._id) && counter < 5) {
              counter++;
              return (
                <RecentPostItem
                  data={item}
                  key={index}
                  setLoading={setLoading}
                />
              );
            }
          })}
        </ul>
        <div></div>
      </div>
    </>
  );
}

export default RecentPostWidget;
