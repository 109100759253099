import React from "react";
import SocialLinkWithIcon from "../SocialLinkWithIcon";

export default function FooterFollowUs() {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-sm-start">
        <div className="footer-item text-sm-start">
          <h5 className="footer-title">Follow Us</h5>
          <ul className="footer-link">
            <SocialLinkWithIcon
              text="Linked "
              icon="bi bi-linkedin"
              link="https://www.linkedin.com/company/com-iot-technologies/"
            />
            <SocialLinkWithIcon
              text="YouTube "
              icon="bi bi-youtube"
              link="https://www.youtube.com/channel/UCvSXiF4O9QbN4mqDWQCftxQ/featured"
            />
            <SocialLinkWithIcon
              text="Twitter "
              icon="bi bi-twitter"
              link="https://twitter.com/comIoTtech"
            />
          </ul>
        </div>
      </div>
    </>
  );
}
