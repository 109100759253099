import React from "react";
import { useState, useEffect, useContext } from "react";
import Footer from "../common/Footer";
import Header from "../common/Header";
import HomePage from "../page/home/HomePage";
import Preloader from "./Preloader";
import { getAllProducts } from "../../APIs/productAPIs";
import { getAllPartners } from "../../APIs/partnerAPIs";
import { getAllNews, getBreakingNews } from "../../APIs/newsAPIs";
import { getAllBlogs } from "../../APIs/blogsAPIs";
import { getAllClientFeedbacks } from "../../APIs/clientFeedbackAPIs";
import { Context } from "../App";

function MainLayout() {
  const { reloadAnimation } = useContext(Context);
  const [allProducts, setAllProducts] = useState();
  const [allPartners, setAllPartners] = useState();
  const [allNews, setAllNews] = useState();
  const [breakingNews, setBreakingNews] = useState();
  const [allBlogs, setAllBlogs] = useState();
  const [allClientFeedbacks, setAllClientFeedbacks] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(async () => {
    const productsData = await getAllProducts(5);
    const partnersData = await getAllPartners(7);
    const newsData = await getAllNews(2);
    const breakingNewsData = await getBreakingNews();
    // const breakingNewsData = await getAllNews(5);
    const blogsData = await getAllBlogs(2);
    const clientFeedbacksData = await getAllClientFeedbacks(2);

    setAllProducts(productsData?.products);
    setAllPartners(partnersData?.partners);
    setAllNews(newsData?.news);
    setBreakingNews(breakingNewsData?.news);
    setAllBlogs(blogsData?.blogs);
    setAllClientFeedbacks(clientFeedbacksData?.clientFeedbacks);
    // setLoading(false);
    setTimeout(() => {
      if (
        productsData &&
        partnersData &&
        newsData &&
        // breakingNews &&
        blogsData &&
        clientFeedbacksData
      ) {
        setLoading(false);
        // reloadAnimation();
      }
    }, 1000);
  }, [loading]);

  return (
    <>
      {loading ? (
        <Preloader />
      ) : (
        <>
          {/* <Header /> */}
          <HomePage
            allProducts={allProducts}
            allPartners={allPartners}
            allNews={allNews}
            breakingNews={breakingNews}
            allBlogs={allBlogs}
            allClientFeedbacks={allClientFeedbacks}
          />
          {/* <Footer /> */}
        </>
      )}
    </>
  );
}

export default MainLayout;
