import React from "react";
import _ from "loadsh";
import TextEditor from "./TextEditor.jsx";

const endPoint = process.env.REACT_APP_END_POINT;

function Section(props) {
  const {
    index,
    onChangeSectionText,
    onDeleteSectionClick,
    section,
    onChangeSectionMediaType,
    handleDataUpload,
    handleRemoveImage,
  } = props;

  return section.sectionType == "section" ? (
    <section>
      <div className="data-input-custom">
        <h4>Section Title</h4>
        <input
          type="text"
          name={`title${index}`}
          id={`title${index}`}
          value={section.sectionTitle}
          onChange={(e) => onChangeSectionText(e, index, "sectionTitle")}
        />
      </div>
      <div className="data-input-custom">
        <h4>Section Text</h4>
        {/* <textarea
          name={`section${index}`}
          id={`section${index}`}
          cols="30"
          rows="3"
          value={section.sectionText}
          placeholder="Type --- before a sentence to make bullet points."
          onChange={(e) => onChangeSectionText(e, index, "sectionText")}
        ></textarea> */}
        <div name={`section${index}`} id={`section${index}`} className="text-editor">
          <TextEditor
            text={section.sectionText}
            customHandleChange={onChangeSectionText}
            args={[index, "sectionText"]}
          />
        </div>
      </div>
      <div className="data-input-custom">
        <h4>Media</h4>
        {/* <input type="number" name="label" id="label" /> */}
        <div>
          <button
            onClick={(e) => onChangeSectionMediaType(e, index, "image")}
            className={section.sectionMediaType == "video" ? "" : "selected-button"}
          >
            Image
          </button>
          <button
            onClick={(e) => onChangeSectionMediaType(e, index, "video")}
            className={section.sectionMediaType == "video" ? "selected-button" : ""}
          >
            Video
          </button>
          {section.sectionMediaType == "video" ? (
            <div className="icon-input-custom">
              <input
                type="text"
                name={`media${index}`}
                placeholder="Youtube Link"
                id={`media${index}`}
                value={section.sectionMediaUrl}
                onChange={(e) => onChangeSectionText(e, index, "sectionMediaUrl")}
              />
            </div>
          ) : (
            <div className="icon-input-custom">
              <input
                type="file"
                name={`media${index}`}
                id={`media${index}`}
                accept="image/*"
                onChange={(e) => handleDataUpload(e, "section", index)}
              />
              <div className="s-icon">
                <img
                  width={80}
                  // height={80}
                  src={section.sectionMediaUrl}
                  // alt="icon"
                />
                {section.sectionMediaUrl != "images/icon/no_img.png" && (
                  <button class="remove-image" onClick={(e) => handleRemoveImage(e, "section", index)}>
                    &times;
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <button onClick={(e) => onDeleteSectionClick(e, index)}>Delete Section</button>
    </section>
  ) : (
    <section>
      <div className="data-input-custom">
        <h4>By</h4>
        <input
          type="text"
          name={`commentBy${index}`}
          id={`commentBy${index}`}
          value={section.sectionCommentBy}
          onChange={(e) => onChangeSectionText(e, index, "sectionCommentBy")}
        />
      </div>
      <div className="data-input-custom">
        <h4>Comment</h4>
        <textarea
          name={`section${index}`}
          id={`section${index}`}
          cols="30"
          rows="3"
          value={section.sectionComment}
          onChange={(e) => onChangeSectionText(e, index, "sectionComment")}
        ></textarea>
      </div>
      <button onClick={(e) => onDeleteSectionClick(e, index)}>Delete Section</button>
    </section>
  );
}

export default Section;
