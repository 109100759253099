import React from "react";
import { Link, Redirect } from "react-router-dom";

export default function SocialMediaLinks(props) {
  const { fbLink, twLink, inLink, prLink, cssClassName, uppercase } = props;

  return (
    <ul className={cssClassName}>
      {fbLink && (
        <li>
          <a rel="noopener noreferrer" href={fbLink} target="_blank">
            {/* {uppercase ? " FB " : "fb"} <i class="bi bi-facebook"></i> */}
            <i class="bi bi-facebook"></i>
          </a>
        </li>
      )}

      {twLink && (
        <li>
          <a rel="noopener noreferrer" href={twLink} target="_blank">
            {/* {uppercase ? " TW " : "tw"} <i class="bi bi-twitter"></i> */}
            <i class="bi bi-twitter"></i>
          </a>
        </li>
      )}

      {inLink && (
        <li>
          <a rel="noopener noreferrer" href={inLink} target="_blank">
            {/* {uppercase ? " IN " : "in"} <i class="bi bi-linkedin"></i> */}
            <i class="bi bi-linkedin"></i>
          </a>
        </li>
      )}
    </ul>
  );
}
