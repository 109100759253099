import React, { useState, useContext } from "react";
import { isAdmin } from "../../../../utils/helpers";
import { Link } from "react-router-dom";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Typography from "@mui/material/Typography";
import { Context } from "../../../App";
import { deleteById } from "../../../../utils/helpers";

export default function EditDeleteBtn(props) {
  const { showModal } = useContext(Context);
  const { color, type, data, someWidth } = props;
  const isAdminUser = isAdmin();
  const [confirmationMessage, setConfirmationMessage] = useState(false);

  const openConfirmationMessage = (e) => {
    setConfirmationMessage(true);
    e.stopPropagation();
  };

  const closeConfirmationMessage = (e) => {
    setConfirmationMessage(false);
    e.stopPropagation();
  };

  return (
    <div>
      {isAdminUser ? (
        <ul
          className="menu-list"
          style={{
            display: "flex",
            justifyContent: "space-around",
            width: (type == "product" || someWidth) && "100px",
            // width: "100px",
            // height: "0px",
            marginTop: "20px",
            color: `${color}`,
          }}
          // onClick={(e) => e.stopPropagation()}
        >
          <li className="menu-item hover-custom">
            <Link
              onClick={(e) => {
                e.stopPropagation();
                showModal(type, data);
              }}
              className="menu-link hover-custom"
            >
              Edit
            </Link>
          </li>
          <li className="menu-item hover-custom">
            <Link
              onClick={openConfirmationMessage}
              className="menu-link hover-custom"
            >
              Delete
            </Link>
          </li>
        </ul>
      ) : null}

      <Dialog
        open={confirmationMessage}
        onClose={closeConfirmationMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        style={{ Color: "#2d2d2d" }}
        className="cursor-auto"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion?</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this{" "}
            {type == "team" ? "member" : type}
          </DialogContentText>
        </DialogContent>
        <DialogActions className="cursor-auto">
          <Button
            onClick={closeConfirmationMessage}
            color="error"
            className="cursor-pointer"
          >
            <Typography variant="subtitle2">Cancel</Typography>
          </Button>
          <Button
            onClick={() => {
              deleteById(data?._id, type);
              closeConfirmationMessage();
            }}
            style={{ marginRight: 8, cursor: "pointer" }}
            color="primary"
            autoFocus
            className="cursor-pointer"
          >
            <Typography variant="subtitle2">Delete</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
