import React from "react";

export default function SubSection({ type, sectionData, title, text }) {
  const setClassName = (type) => {
    switch (type) {
      case "project":
        return "para mb-30";

      case "news":
        return "para-style mb-30";

      default:
        return "mb-30";
    }
  };
  return (
    <>
      {sectionData?.title && <h4>{sectionData?.title}</h4>}
      <div
        className={`styled-text ${setClassName(type)}`}
        dangerouslySetInnerHTML={{
          __html: sectionData?.text.replace(/\n/g, "<br>"),
        }}
      />
      {/* <p className={setClassName(type)}>
        {sectionData?.text.includes("---")
          ? sectionData?.text
              .split("---")
              .slice(1)
              .map((item, index) => {
                return <li key={index}>{item}</li>;
              })
          : sectionData?.text}
      </p> */}
    </>
  );
}
