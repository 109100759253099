import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import YouTube from "react-youtube";
import Spinner from "../../common/Spinner";
import SwiperCore, {
  Autoplay,
  EffectFade,
  Navigation,
  Pagination,
} from "swiper";
import "swiper/css/autoplay";
import "../../../index.css"

import { Link } from "react-router-dom";
SwiperCore.use([Navigation, Pagination, EffectFade, Autoplay]);
function Banner() {
  const [isLoading, setLoading] = useState(true);
  const bannerslider = {
    slidesPerView: 1,
    speed: 1000,
    spaceBetween: 0,
    loop: false,
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    centeredSlides: true,
    roundLengths: true,
    navigation: {
      nextEl: ".banner-next",
      prevEl: ".banner-prev",
    },
    coverflowEffect: {
      rotate: 30,
      slideShadows: false,
    },
    direction: "horizontal",
    pagination: {
      clickable: true,
      el: ".swiper-pagination",
    },
  };
  const opts = {
    height: "390",
    width: "640",
    playerVars: {
      // https://developers.google.com/youtube/player_parameters
      autoplay: 1,
    },
  };
  return (
    <>
      <div
        style={{
          maxHeight: "100vh",
          overflow: "hidden",
          opacity: "1",
          backgroundColor: "white",
        }}
      >
        {/* {isLoading && <Spinner />} */}
        {/* <video autoPlay loop muted playsInline width="100%">
          <source src="videos/video.mp4" type="video/mp4" />
        </video> */}
        <img
        src={"thumb.jpg"}
        className="video-thumb tiny"
        alt="thumb"
        style={{ opacity: isLoading ? 1 : 0 }}
        width="100%"
        />
        <video
          loop
          muted
          autoPlay
          playsInline
          src={"videos/video.mp4"}
          preload={"auto"}
          type={"video/mp4"}
          width="100%"
          style={{ opacity: isLoading ? 0 : 1 }}
          onLoadedData={() => setLoading(false) }
        ></video>
      </div>
    </>
  );
}

export default Banner;
