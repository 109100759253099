import React, { useEffect, useReducer, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import WOW from "wowjs";
import { isLoggedIn, signOut } from "../../utils/helpers";
import HeaderNavItem from "./HeaderNavItem";
import HeaderSocialLinkedIn from "./HeaderSocialLinks";

/*---------Using reducer mange the active or inactive menu----------*/
const initialState = { activeMenu: "" };

function reducer(state, action) {
    switch (action.type) {
        case "homeOne":
            return { activeMenu: "homeOne" };
        case "service":
            return { activeMenu: "service" };
        case "projects":
            return { activeMenu: "projects" };
        case "pages":
            return { activeMenu: "pages" };
        case "blogs":
            return { activeMenu: "blogs" };
        default:
            return { activeMenu: "" };
    }
}

function Header() {
    const location = useLocation();
    const [state, dispatch] = useReducer(reducer, initialState);
    // Sticky Navbar
    useEffect(() => {
        const header = document.querySelector(".header-section");
        if (location.pathname != "/") header.classList.add("sticky");
        new WOW.WOW({
            live: false,
        }).init();
        window.addEventListener("scroll", isSticky);
        return () => {
            window.removeEventListener("scroll", isSticky);
        };
    });
    const isSticky = (e) => {
        const header = document.querySelector(".header-section");
        const menuscrollTop = window.scrollY;
        menuscrollTop >= 20 || location.pathname != "/"
            ? header.classList.add("sticky")
            : header.classList.remove("sticky");
    };

    /*----for single sidebar event use one state-------*/
    const [sidebar, setSidebar] = useState(false);
    const [sidebarleft, setSidebarleft] = useState(false);
    const [searchBar, setSearchBar] = useState(false);
    const handleSearchbar = () => {
        if (searchBar === false || searchBar === 0) {
            setSearchBar(1);
        } else {
            setSearchBar(false);
        }
    };
    const showSidebar = () => {
        if (sidebar === false || sidebar === 0) {
            setSidebar(1);
        } else {
            setSidebar(false);
        }
    };
    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
    const showSidebarleft = () => {
        if (sidebarleft === false || sidebarleft === 0) {
            setSidebarleft(1);
        } else {
            setSidebarleft(false);
        }
    };

    return (
        <>
            {/* <AnimatedCursor
        className="coursor"
        innerSize={8}
        outerSize={30}
        color="230, 230, 230"
        outerAlpha={0.5}
        innerScale={0.7}
        outerScale={1.5}
        zIndex="99999"
        clickables={[
          "a",
          "i",
          'input[type="text"]',
          'input[type="email"]',
          'input[type="number"]',
          'input[type="submit"]',
          'input[type="image"]',
          "label[for]",
          "select",
          "textarea",
          "button",
          ".link",
          ".sub-menu",
          ".has-child",
          ".menu-toggle-wrap",
          ".search-cross-btn",
          ".SRLControls",
        ]}
      /> */}

            <header className="header-section">
                <div className="container-fluid">
                    <div className="header-area">
                        <div className="header-logo">
                            <Link onClick={scrollTop} to={"/"}>
                                <img
                                    height="70px"
                                    src={
                                        process.env.PUBLIC_URL +
                                        "/Logo-White.png"
                                    }
                                    alt="images"
                                />
                            </Link>
                        </div>

                        <div className="mobile-menu-btn text-white d-lg-none d-block">
                            <i onClick={showSidebar} className="bi bi-list" />
                        </div>

                        <div
                            className={
                                sidebar === 1
                                    ? "main-menu show-menu"
                                    : "main-menu"
                            }
                        >
                            <div className="mobile-logo-area d-lg-none d-block">
                                <div className="mobile-logo-wrap d-flex justify-content-between align-items-center">
                                    <img
                                        src={
                                            process.env.PUBLIC_URL +
                                            "/Logo-White.png"
                                        }
                                        alt="images"
                                        width={150}
                                    />
                                    <div
                                        className="menu-close-btn"
                                        onClick={showSidebar}
                                    >
                                        <i className="bi bi-x-lg" />
                                    </div>
                                </div>
                            </div>

                            <ul className="menu-list">
                                <HeaderNavItem link="#" text="HOME" />
                                <HeaderNavItem
                                    link="about-us"
                                    text="ABOUT US"
                                />
                                <HeaderNavItem
                                    link="solutions"
                                    text="Solutions"
                                />
                                {/* <HeaderNavItem link="solutions" text="SOLUTIONS" /> */}
                                {/* <HeaderNavItem link="projects" text="PROJECTS" /> */}
                                <HeaderNavItem
                                    link="partners"
                                    text="PARTNERS"
                                />
                                <HeaderNavItem link="news" text="NEWS" />
                                <HeaderNavItem link="blogs" text="BLOG" />
                                <HeaderNavItem
                                    link="customer-testimonies"
                                    text="Customer Testimonies"
                                />
                                <HeaderNavItem
                                    link="contact-us"
                                    text="CONTACT US"
                                />
                                {isLoggedIn() && (
                                    <div
                                        onClick={() => {
                                            signOut();
                                            window.location.reload(false);
                                        }}
                                    >
                                        <HeaderNavItem link="#" text="LOGOUT" />
                                    </div>
                                )}
                            </ul>

                            {/* Main navbar old one*/}
                            {/* <ul className="menu-list">
                <li
                  className="menu-item"
                  onClick={() => dispatch({ type: "homeOne" })}
                >
                  <Link to={"#"} className="menu-link drop-down">
                    Home
                    <i className="bx bx-chevron-down dropdown-icon" />
                  </Link>
                  <ul
                    className={
                      state.activeMenu === "homeOne"
                        ? "submenu d-block"
                        : "submenu d-none"
                    }
                  >
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/`}
                      >
                        Home 1
                      </Link>
                    </li>
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/index2`}
                      >
                        home 2
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="menu-item">
                  <Link
                    to={"#"}
                    className="menu-link drop-down"
                    onClick={() => dispatch({ type: "projects" })}
                  >
                    project
                    <i className="bx bx-chevron-down dropdown-icon" />
                  </Link>
                  <ul
                    className={
                      state.activeMenu === "projects"
                        ? "submenu d-block"
                        : "submenu d-none"
                    }
                  >
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/projects`}
                      >
                        projects
                      </Link>
                    </li>
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/project-details`}
                      >
                        projects Details
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="menu-item"
                  onClick={() => dispatch({ type: "service" })}
                >
                  <Link to={"#"} className="menu-link drop-down">
                    services
                    <i className="bx bx-chevron-down dropdown-icon" />
                  </Link>
                  <ul
                    className={
                      state.activeMenu === "service"
                        ? "submenu d-block"
                        : "submenu d-none"
                    }
                  >
                    <li className="sub-item">
                      <Link
                        to={`${process.env.PUBLIC_URL}/service`}
                        onClick={scrollTop}
                      >
                        services
                      </Link>
                    </li>
                    <li className="sub-item">
                      <Link
                        to={`${process.env.PUBLIC_URL}/service-details`}
                        onClick={scrollTop}
                      >
                        services Details
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  className="menu-item"
                  onClick={() => dispatch({ type: "blogs" })}
                >
                  <Link to={"#"} className="menu-link drop-down">
                    blog
                    <i className="bx bx-chevron-down dropdown-icon" />
                  </Link>
                  <ul
                    className={
                      state.activeMenu === "blogs"
                        ? "submenu d-block"
                        : "submenu d-none"
                    }
                  >
                    <li className="sub-item">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/blog-grid`}
                      >
                        blog grid
                      </NavLink>
                    </li>
                    <li className="sub-item">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/blog-sidebar`}
                      >
                        Blog sidebar
                      </NavLink>
                    </li>
                    <li className="sub-item">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/blog-details`}
                      >
                        Blog details
                      </NavLink>
                    </li>
                  </ul>
                </li>

                <li
                  className="menu-item"
                  onClick={() => dispatch({ type: "pages" })}
                >
                  <Link to={"#"} className="menu-link drop-down">
                    Pages
                    <i className="bx bx-chevron-down dropdown-icon" />
                  </Link>
                  <ul
                    className={
                      state.activeMenu === "pages"
                        ? "submenu d-block"
                        : "submenu d-none"
                    }
                  >
                    <li className="sub-item">
                      <NavLink
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/team`}
                      >
                        team
                      </NavLink>
                    </li>
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/feedback`}
                      >
                        feedback
                      </Link>
                    </li>
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/faq`}
                      >
                        faq
                      </Link>
                    </li>
                    <li className="sub-item">
                      <Link
                        onClick={scrollTop}
                        to={`${process.env.PUBLIC_URL}/error`}
                      >
                        Error-404
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="menu-item">
                  <Link
                    onClick={scrollTop}
                    to={`${process.env.PUBLIC_URL}/contact-us`}
                    className="menu-link"
                  >
                    CONTACT
                  </Link>
                </li>
              </ul> */}

                            {/* mobile-search-area */}
                            <div className="mobile-menu-bottom d-lg-none d-block">
                                {/* <HeaderStartProjectBtn screenDevice="mobile" /> */}
                                <HeaderSocialLinkedIn />
                            </div>
                        </div>

                        <div className="nav-right-area">
                            {/* <HeaderStartProjectBtn screenDevice="desktop" /> */}
                            <HeaderSocialLinkedIn />

                            {/* <div
                onClick={handleSearchbar}
                className="search-icon-header search-btn"
              >
                <i className="bx bx-search-alt-2" />
              </div> */}
                            {/* <div
                onClick={showSidebarleft}
                className="sidebar-header menu-sidebar-btn"
              >
                <i className="bx bx-menu-alt-left" />
              </div> */}
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default Header;
