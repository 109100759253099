import React from "react";
import Comment from "./Comment";
import SubSection from "./SubSection";
import SubSectionMedia from "./SubSectionMedia";

export default function Sections({ sectionsData, type }) {
  let mediaPosition = 0;
  const setSection = (section, index) => {
    if (section?.type == "comment")
      return <Comment key={index} commentData={section} type={type} />;

    if (section?.type == "section" && !section?.mediaURL)
      return <SubSection key={index} sectionData={section} type={type} />;

    if (section?.type == "section" && section?.mediaURL)
      return (
        <SubSectionMedia
          key={index}
          sectionData={section}
          type={type}
          mediaPosition={mediaPosition++}
        />
      );
  };

  return (
    <div>
      {sectionsData?.map((section, index) => {
        return setSection(section, index);
      })}
    </div>
  );
}
