import axios from "axios";

const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const getBlogById = async (id) => {
  try {
    const res = await axios.get(`${endPoint}/blogs/${id}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const getPrevNextBlogs = async (date) => {
  try {
    const res = await axios.get(`${endPoint}/blogs-prevNext?date=${date}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const addOrUpdateBlogs = async (formData, id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    let res;
    if (id) res = await axios.put(`${endPoint}/blogs/${id}`, formData, auth);
    else res = await axios.post(`${endPoint}/blogs`, formData, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
export const getAllBlogs = async (limit, page = 1) => {
  try {
    let limitURL = "";
    if (limit) limitURL = `?limit=${limit}&page=${page}`;
    const res = await axios.get(`${endPoint}/blogs${limitURL}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const deleteBlogs = async (id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.delete(`${endPoint}/blogs/${id}`, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
