import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // import the styles
import { useEffect, useState } from "react";

const TextEditor = (props) => {
  const { text, setText, customHandleChange, args } = props;

  const [value, setValue] = useState(text || "");

  useEffect(() => {
    setValue(text);
  }, [text]);

  function handleChange(newValue) {
    setValue(newValue);
    if (setText) setText(newValue);
    if (customHandleChange && args) {
      customHandleChange(newValue, ...args);
    }
  }

  const modules = {
    toolbar: [
      // [{ header: "1" }, { header: "2" }],
      // [{ size: [] }],
      ["bold", "italic", "underline", "strike"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };
  /*
   * Quill editor formats
   * See https://quilljs.com/docs/formats/
   */
  const formats = [
    "header",
    "size",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "indent",
    "link",
    "image",
    "video",
  ];

  return (
    // <div style={{ backgroundColor: "white", color: "black" }}>
    <ReactQuill
      value={value}
      onChange={handleChange}
      modules={modules}
      formats={formats}
      style={{ height: "350px", marginBottom: "2.7rem" }}
      // theme="snow"
    />
    // </div>
  );
};

export default TextEditor;
