import React from "react";
import { Link } from "react-router-dom";

export default function NewsInfo({ date, by }) {
  const setDate = (date) => {
    return new Date(date).toLocaleString("default", {
      month: "long",
      day: "numeric",
      year: "numeric",
    });
  };
  return (
    <>
      <ul className="blog-post-meta d-flex justify-content-start flex-wrap">
        {by && (
          <li>
            <i className="bi bi-person-fill" />
            <span>By {by}</span>
          </li>
        )}
        {date && (
          <li>
            <i className="bi bi-clock" />
            <span>{setDate(date)} </span>
          </li>
        )}
      </ul>
    </>
  );
}
