import React, { useContext, useEffect } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import ContactWrap from "./ContactWrap";
import { Context } from "../../App";

function ContaceUsPage() {
  const { reloadAnimation } = useContext(Context);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  useEffect(() => {
    scrollTop();
    reloadAnimation();
  }, []);
  return (
    <>
      <Breadcrumb name="Contact Us" />
      <ContactWrap />
    </>
  );
}

export default ContaceUsPage;
