import React from "react";

import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "./components/App";
import MainLayout from "./components/layout/MainLayout";
// import all css
import Login from "./components/page/Login";
import AboutPage from "./components/page/about/AboutPage";
import BlogDetailsPage from "./components/page/blogDetails/BlogDetailsPage";
import BlogGridPage from "./components/page/blogGrid/BlogGridPage";
import BlogSidebarPage from "./components/page/blogSidebar/BlogSidebarPage";
import ContaceUsPage from "./components/page/contactUs/ContaceUsPage";
import ErrorPage from "./components/page/error/ErrorPage";
import FeedbackPage from "./components/page/feedback/FeedbackPage";
import PartnerPage from "./components/page/partner/PartnerPage";
import ProductDetailsPage from "./components/page/productDetails/ProductDetailsPage";
import ProductPage from "./components/page/products/ProductPage";
import "./index.css";

/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide
// console.log = console.warn = console.error = () => {};

function Root() {
    return (
        <>
            <BrowserRouter basename="/">
                <Layout>
                    <Switch>
                        {/*main layout*/}
                        <Route exact path="/" component={MainLayout} />
                        {/* secound layout */}
                        {/* <Route exact path="/index2" component={HomePageTwoLayout} /> */}
                        {/* all inner page load layout component */}

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/login`}
                            component={Login}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/about-us`}
                            component={AboutPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/contact-us`}
                            component={ContaceUsPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/partners`}
                            component={PartnerPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/customer-testimonies`}
                            component={FeedbackPage}
                        />
                        {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={FaqPage}
            /> */}
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/blog-sidebar`}
                            component={BlogSidebarPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/news`}
                            component={BlogGridPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/news/:id`}
                            component={BlogDetailsPage}
                        />

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/blogs`}
                            component={BlogGridPage}
                        />
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/blogs/:id`}
                            component={BlogDetailsPage}
                        />

                        {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/projects`}
              component={ProjectPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/projects/:id`}
              component={ProjectDetailsPage}
            /> */}
                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/solutions`}
                            component={ProductPage}
                        />

                        <Route
                            exact
                            path={`${process.env.PUBLIC_URL}/solutions/:id`}
                            component={ProductDetailsPage}
                        />

                        {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/solutions`}
              component={ServicePage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/solutions/:id`}
              component={SolutionDetailsPage}
            /> */}
                        <Route component={ErrorPage} />
                    </Switch>
                </Layout>
            </BrowserRouter>
        </>
    );
}

ReactDOM.render(
    <React.StrictMode>
        <SimpleReactLightbox>
            <Root />
        </SimpleReactLightbox>
    </React.StrictMode>,
    document.getElementById("root")
);
