import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import FeedbackWrap from "./FeedbackWrap";

function FeedbackPage() {
    return (
        <>
            <Breadcrumb name="Customer Testimonies" />
            <FeedbackWrap />
        </>
    );
}

export default FeedbackPage;
