import React from "react";

export default function SocialLinkWithIcon({ text, icon, link }) {
  return (
    <li>
      <a rel="noopener noreferrer" href={link} target="_blank">
        {text}
        <i class={icon}></i>
      </a>
    </li>
  );
}
