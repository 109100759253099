import axios from "axios";

const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const getNewsById = async (id) => {
  try {
    const res = await axios.get(`${endPoint}/news/${id}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const getPrevNextNews = async (date) => {
  try {
    const res = await axios.get(`${endPoint}/news-prevNext?date=${date}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};
export const getBreakingNews = async () => {
  try {
    const res = await axios.get(`${endPoint}/news-breaking`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const addOrUpdateNews = async (formData, id) => {
  try {
    // console.log(
    //   "🚀 ~ file: newsAPIs.js ~ line 37 ~ addOrUpdateNews ~ formData",
    //   ...formData
    // );
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    let res;
    if (id) res = await axios.put(`${endPoint}/news/${id}`, formData, auth);
    else res = await axios.post(`${endPoint}/news`, formData, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
export const getAllNews = async (limit, page = 1) => {
  try {
    let limitURL = "";
    if (limit) limitURL = `?limit=${limit}&page=${page}`;
    const res = await axios.get(`${endPoint}/news${limitURL}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const deleteNews = async (id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.delete(`${endPoint}/news/${id}`, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
