import React, { Fragment } from "react";

function Media(props) {
  const {
    type,
    setType,
    name,
    url,
    setUrl,
    handleDataUpload,
    handleRemoveImage,
    uploadType,
    title,
    error,
  } = props;
  return (
    <div className="data-input-custom">
      <h4>{title}</h4>
      <div>
        <button
          onClick={(e) => {
            e.preventDefault();
            setUrl("images/icon/no_img.png");
            setType("image");
          }}
          className={type == "video" ? "" : "selected-button"}
        >
          Image
        </button>
        <button
          onClick={(e) => {
            e.preventDefault();
            setUrl("");
            setType("video");
          }}
          className={type == "video" ? "selected-button" : ""}
        >
          Video
        </button>
        {type == "video" ? (
          <div className="icon-input-custom">
            <input
              type="text"
              name={name}
              placeholder="Youtube Link"
              id={name}
              value={url}
              onChange={(e) => setUrl(e.target.value)}
            />
          </div>
        ) : (
          <div className="icon-input-custom">
            <input
              type="file"
              name={name}
              id={name}
              accept="image/*"
              onChange={(e) => handleDataUpload(e, uploadType)}
            />
            <div className="s-icon">
              <img
                width={80}
                // height={80}

                src={url}
                // alt="icon"
              />
              {url != "images/icon/no_img.png" && (
                <button
                  class="remove-image"
                  onClick={(e) => handleRemoveImage(e, uploadType)}
                >
                  &times;
                </button>
              )}
            </div>
          </div>
        )}
      </div>
      <p>{error}</p>
    </div>
  );
}

export default Media;
