import jwt from "jwt-decode";
import { deleteBlogs } from "../APIs/blogsAPIs";
import { deleteFeedback } from "../APIs/clientFeedbackAPIs";
import { deleteNews } from "../APIs/newsAPIs";
import { deletePartner } from "../APIs/partnerAPIs";
import { deleteProduct } from "../APIs/productAPIs";
import { deleteProject } from "../APIs/projectAPIs";
import { deleteSolution } from "../APIs/solutionAPIs";

// export const END_POINT = "http://localhost:5000";
export const END_POINT = process.env.REACT_APP_END_POINT;
// export const END_POINT = "";

// export const setEndPoint = () => {
//   if (process.env.NODE_ENV != "production")
//     return process.env.REACT_APP_END_POINT_DEV;
//   else return process.env.REACT_APP_END_POINT_PRODUCTION;
// };

export const isLoggedIn = () => {
    const token = localStorage.getItem("token");
    if (token) {
        const decodedToken = jwt(token);
        if (Date.now() <= decodedToken.exp * 1000) {
            return true;
        }
    }
    return false;
};

export const locationIncrement = async (location) => {
    try {
        const res = await fetch(END_POINT + `/location`, {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json",
            },
            body: JSON.stringify({
                location,
            }),
        });
        return res.json();
    } catch (e) {}
};

export const isAdmin = () => {
    const token = localStorage.getItem("token");
    if (token) {
        const decodedToken = jwt(token);
        if (
            Date.now() <= decodedToken.exp * 1000 &&
            (decodedToken.isAdmin || decodedToken.isSuperAdmin)
        ) {
            return true;
        }
    }

    return false;
};

export const signOut = () => {
    const token = localStorage.removeItem("token");
};

export const deleteById = async (id, type) => {
    if (type == "solution") await deleteSolution(id);
    if (type == "project") await deleteProject(id);
    if (type == "news") await deleteNews(id);
    if (type == "Customer Testimony") await deleteFeedback(id);
    if (type == "partner") await deletePartner(id);
    if (type == "blogs") await deleteBlogs(id);
    if (type == "product") await deleteProduct(id);
};
