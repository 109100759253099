import React, { useEffect } from "react";
import Banner from "./Banner";
import Sponsor from "../../common/Sponsor";
import JoinUs from "../../common/JoinUs";
import HomeOneProjects from "./HomeOneProjects";
import HomeoneSolutions from "./HomeoneSolutions";
import FeedbackArea from "./FeedbackArea";
import NewsArea from "./NewsArea";
import TeamArea from "./TeamArea";
import PartnerArea from "./PartnerArea";
import AchivmentArea from "../../common/AchivementArea";
import AboutArea from "./AboutArea";
import BlogArea from "./BlogArea";
import ProductArea from "./ProductArea";
import BreakingNewsLoop from "./BreakingNewsLoop";

function HomePage({
  allProducts,
  allPartners,
  allNews,
  breakingNews,
  allBlogs,
  allClientFeedbacks,
}) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(async () => {
    scrollTop();
  }, []);

  return (
    <>
      <Banner />
      {breakingNews?.length ? <BreakingNewsLoop data={breakingNews} /> : null}
      <ProductArea data={allProducts} />
      {/* <HomeoneSolutions showModal={showModal} data={allSolutions} /> */}
      {/* <HomeOneProjects showModal={showModal} /> */}
      <AboutArea />
      {/* <AchivmentArea /> */}
      {/* <TeamArea showModal={showModal} /> */}
      <PartnerArea data={allPartners} />
      <NewsArea data={allNews} />
      <BlogArea data={allBlogs} />
      <FeedbackArea data={allClientFeedbacks} />
      {/* <JoinUs padding="pt-120"  /> */}
      {/* <Sponsor /> */}
    </>
  );
}

export default HomePage;
