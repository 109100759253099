import React from "react";
import TextEditor from "./TextEditor";

const endPoint = process.env.REACT_APP_END_POINT;

function ClientFeedback(props) {
    const {
        clientNameFB,
        clientNameError,
        setClientNameFB,
        clientPosition,
        clientPositionError,
        setClientPosition,
        handleDataUpload,
        clientImageUrl,
        clientImageUrlError,
        feedback,
        feedbackError,
        setFeedback,
        type,
        number,
        numberError,
        setNumber,
        clientFB,
        clientFBError,
        setClientFB,
        clientIN,
        clientINError,
        setClientIN,
        clientTW,
        clientTWError,
        setClientTW,
    } = props;
    return (
        <section>
            <div className="data-input-custom">
                <h4>Client Name</h4>
                <input
                    type="text"
                    name="clientNameFB"
                    value={clientNameFB}
                    onChange={(e) => setClientNameFB(e.target.value)}
                    id="clientNameFB"
                />
                <p>{clientNameError}</p>
            </div>
            {setNumber ? (
                <div className="data-input-custom">
                    <h4>Number</h4>
                    <input
                        type="number"
                        name="number"
                        value={number}
                        onChange={(e) => setNumber(e.target.value)}
                        id="number"
                        min={1}
                    />
                    <p>{numberError}</p>
                </div>
            ) : null}

            <div className="data-input-custom">
                <h4>Client Position</h4>
                <input
                    type="text"
                    name="clientPosition"
                    value={clientPosition}
                    onChange={(e) => setClientPosition(e.target.value)}
                    id="clientPosition"
                />
                <p>{clientPositionError}</p>
            </div>
            <div className="data-input-custom">
                <h4>FB</h4>
                <input
                    type="text"
                    name="clientFB"
                    value={clientFB}
                    onChange={(e) => setClientFB(e.target.value)}
                    id="clientFB"
                />
                <p>{clientFBError}</p>
            </div>
            <div className="data-input-custom">
                <h4>TW</h4>
                <input
                    type="text"
                    name="clientTW"
                    value={clientTW}
                    onChange={(e) => setClientTW(e.target.value)}
                    id="clientTW"
                />
                <p>{clientTWError}</p>
            </div>
            <div className="data-input-custom">
                <h4>IN</h4>
                <input
                    type="text"
                    name="clientIN"
                    value={clientIN}
                    onChange={(e) => setClientIN(e.target.value)}
                    id="clientIN"
                />
                <p>{clientINError}</p>
            </div>
            <div className="data-input-custom">
                <h4>Client Image</h4>
                <div className="icon-input-custom">
                    <input
                        type="file"
                        name="clientImage"
                        id="clientImage"
                        onChange={(e) => handleDataUpload(e, "clientImage")}
                        accept="image/*"
                    />
                    <div className="s-icon">
                        {type == "feedback" ? (
                            <img width={80} src={clientImageUrl} />
                        ) : (
                            <img
                                width={80}
                                height={80}
                                style={{ borderRadius: "100%" }}
                                src={clientImageUrl}
                            />
                        )}
                    </div>
                </div>
            </div>
            <div className="data-input-custom">
                <h4>Client Feedback</h4>
                <div
                    name="mainSection"
                    id="mainSection"
                    className="text-editor"
                >
                    <TextEditor text={feedback} setText={setFeedback} />
                </div>
                {/* <textarea
          name="feedback"
          id="feedback"
          cols="30"
          rows="3"
          value={feedback}
          onChange={(e) => setFeedback(e.target.value)}
        ></textarea> */}
                <p>{feedbackError}</p>
            </div>
        </section>
    );
}

export default ClientFeedback;
