import React, { useState, useEffect } from "react";
import ModalVideo from "react-modal-video";

export default function Video({ url }) {
  const [isOpen, setOpen] = useState(false);
  const [videoId, setVideoId] = useState();

  useEffect(async () => {
    setVideoId(getVideoId(url));
  }, [url]);

  const getVideoId = (url) => {
    // Our regex pattern to look for a youTube ID
    const regExp =
      /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
    //Match the url with the regex
    const match = url?.match(regExp);
    //Return the result
    return match && match[2].length === 11 ? match[2] : undefined;
  };

  return (
    <>
      {videoId && (
        <div className="position-relative">
          <div className="video-area">
            <div style={{ cursor: "pointer" }} className="video-play">
              <div
                onClick={() => setOpen(true)}
                className="video-open video-icon"
              >
                <i className="bx bx-play" />
              </div>
            </div>
            <img
              src={`http://img.youtube.com/vi/${videoId}/maxresdefault.jpg`}
              alt="images"
            />
          </div>
          <React.Fragment>
            <ModalVideo
              channel="youtube"
              isOpen={isOpen}
              videoId={videoId}
              onClose={() => setOpen(false)}
            />
          </React.Fragment>
        </div>
      )}
    </>
  );
}
