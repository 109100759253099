import React from "react";
import { Link } from "react-router-dom";

export default function PaginationItem({ page, setPage }) {
  return (
    <>
      <li className="page-item">
        <Link
          className="page-link active hover-btn"
          onClick={() => setPage(page)}
        >
          <span style={{ color: "#555555" }}>{page}</span>
        </Link>
      </li>
    </>
  );
}
