import React from "react";
import { Link } from "react-router-dom";

export default function FooterInfo() {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div>
      <div className="footer-left-address">
        <Link to={`${process.env.PUBLIC_URL}/`} onClick={scrollTop}>
          <img
            src={process.env.PUBLIC_URL + "/Logo-White.png"}
            className="footer-logo"
            alt="images"
          />
        </Link>
        <ul className="address-list">
          <li>
            <Link onClick={scrollTop} to="mailto:Info@com-iot.com">
              Email: Info@com-iot.com
            </Link>
          </li>
          <li>
            <Link onClick={scrollTop} to={"#"}>
              Address: Office 1107, JLT, Dubai, UAE
            </Link>
          </li>
          <li>
            <a href="tel:+8801761111456">Phone: +9714 264 2828</a>
          </li>
        </ul>
      </div>
    </div>
  );
}
