import React from "react";
import { Link } from "react-router-dom";

const endPoint = process.env.REACT_APP_END_POINT;

export default function RecentPostItem({ data, setLoading }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleClick = () => {
    scrollTop();
    setLoading(true);
  };

  const setDate = (date) => {
    return new Date(date).toLocaleString("default", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  };

  return (
    <>
      <li className="recent-post-item">
        <div className="post-image">
          <img src={endPoint + data?.wallImageURL} alt="images" />
        </div>
        <div className="post-content">
          <p className="date">{setDate(data?.date)}</p>
          <h6>
            <Link to={data?._id} onClick={handleClick}>
              {data?.title}
            </Link>
          </h6>
        </div>
      </li>
    </>
  );
}
