import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

export default function BreakingNewsLoop({ data }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const [duplicatedNews, setDuplicatedNews] = useState([]);

  useEffect(() => {
    let scrollWidth = document.querySelector(".news-container ul").scrollWidth;
    let duration = 22;

    // if (data?.length < 4) scrollWidth -= (4 - data?.length) * 400;

    // if (data?.length > 2) duration += (data?.length - 2) * 5;

    if (data?.length < 2) {
      setDuplicatedNews(Array(4).fill(data).flat());
      if (scrollWidth < 600) {
        duration = Math.round(scrollWidth * 0.035);
        scrollWidth *= 2;
      }
    } else if (data?.length < 4) setDuplicatedNews(Array(2).fill(data).flat());

    // set CSS variable
    let root = document.querySelector(":root");
    root.style.setProperty(`--scrollWidth`, `-${scrollWidth}px`);
    root.style.setProperty(`--duration`, `${duration}s`);
  }, []);

  return (
    <div class="news-container">
      <div class="title">Our Latest News</div>
      <ul>
        {duplicatedNews?.map((item, index) => {
          return (
            <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/news/${item?._id}`} key={index}>
              <li>{item?.title}</li>
            </Link>
          );
        })}
      </ul>
    </div>
  );
}
