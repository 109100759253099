import React, { useState, useEffect, useContext } from "react";
import { useHistory, useParams } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import JoinUs from "../../common/JoinUs";
import Sponsor from "../../common/Sponsor";
import ProductDetailsWrap from "./ProductDetailsWrap";
import { getProductById } from "../../../APIs/productAPIs";
import Spinner from "../../common/Spinner";
import { Context } from "../../App";
import { Helmet } from "react-helmet";

const endPoint = process.env.REACT_APP_END_POINT;

function ProductDetailsPage() {
  const { reloadAnimation } = useContext(Context);
  const [productDetailsData, setProductDetailsData] = useState();
  // let productID = "6322d828fea2809bbde70337";
  let { id } = useParams();
  let history = useHistory();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(async () => {
    scrollTop();
    const data = await getProductById(id.replaceAll("_", " "));
    if (!data?.product) history.push("/not-found");
    else setProductDetailsData(data?.product);
    reloadAnimation();
  }, [id]);

  return (
    <>
      {productDetailsData && (
        <Helmet>
          <meta name="description" content={productDetailsData?.summary} />
          <meta property="og:url" content={endPoint + `/solutions/${id}`} />
          <meta property="og:type" content="product" />
          <meta property="og:title" content={productDetailsData?.title} />
          <meta
            property="og:description"
            content={productDetailsData?.summary}
          />
          <meta
            property="og:image"
            content={endPoint + productDetailsData?.wallImageURL}
          />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content="@com-iot" />
          <meta
            property="twitter:url"
            content={endPoint + `/solutions/${id}`}
          />
          <meta name="twitter:title" content={productDetailsData?.title} />
          <meta
            name="twitter:description"
            content={productDetailsData?.summary}
          />
          <meta
            name="twitter:image"
            content={endPoint + productDetailsData?.wallImageURL}
          />
          <title>{productDetailsData?.title}</title>
        </Helmet>
      )}
      <Breadcrumb name="Solution Details" />
      {productDetailsData ? (
        <>
          <ProductDetailsWrap data={productDetailsData} />
        </>
      ) : (
        <Spinner biggerHeight />
      )}

      {/* <JoinUs padding="pt-120" /> */}
      {/* <Sponsor /> */}
    </>
  );
}

export default ProductDetailsPage;
