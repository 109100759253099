import React from "react";
import Media from "./Media";

export default function SubSectionMedia(props) {
  const { mediaPosition, sectionData, type } = props;
  console.log("🚀 ~ SubSectionMedia ~ sectionData:", sectionData);

  const setClassName = () => {
    if (type == "news") return "para-dark";
    else return "para";
  };

  const mediaComponent = () => {
    return (
      <div className="col-md-6 col-sm-6">
        <Media mediaUrl={sectionData?.mediaURL} />
      </div>
    );
  };
  const textComponent = () => {
    return (
      <div className="col-md-6 col-sm-6">
        <h6>{sectionData?.title}</h6>
        <div
          className={`styled-text ${setClassName()}`}
          dangerouslySetInnerHTML={{
            __html: sectionData?.text.replace(/\n/g, "<br>"),
          }}
        />
        {/* <p className={setClassName()}>{sectionData?.text}</p> */}
      </div>
    );
  };
  return (
    <>
      <div className="row g-3 service-intro-row subsection-media">
        {parseInt(mediaPosition) % 2 == 0 ? (
          <>
            {mediaComponent()}
            {textComponent()}
          </>
        ) : (
          <>
            {textComponent()}
            {mediaComponent()}
          </>
        )}
      </div>
    </>
  );
}
