import React from "react";
import { Link } from "react-router-dom";
import TeamPartnerCard from "./TeamPartnerUtils/TeamPartnerCard";
import AddOrAllSection from "./CommonUtils/AddOrAllSection";

function PartnerArea(props) {
  const { showModal, data } = props;
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="team-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>Our Partners</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            {data?.slice(0, 3).map((partner, index) => {
              return (
                <TeamPartnerCard
                  data={partner}
                  key={index}
                  wowDelay={0.7 - 0.1 * index}
                  teamOrPartner="partner"
                  colWidth="col-lg-3"
                />
              );
            })}

            <div
              className="col-lg-3 col-md-6 col-sm-8 order-lg-1 order-2 wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <div className="section-card team-card">
                <AddOrAllSection
                  showModal={showModal}
                  link="partners"
                  type="partner"
                />
              </div>
            </div>

            {data?.slice(3).map((partner, index) => {
              return (
                <TeamPartnerCard
                  data={partner}
                  key={index}
                  wowDelay={0.8 + 0.1 * index}
                  teamOrPartner="partner"
                  colWidth="col-lg-3"
                />
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerArea;
