import React from "react";
import { Link } from "react-router-dom";

export default function HeaderNavItem({ link, text }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  return (
    <div>
      <li className="menu-item">
        <Link
          onClick={scrollTop}
          to={`${process.env.PUBLIC_URL}/${link}`}
          className="menu-link"
        >
          {text}
        </Link>
      </li>
    </div>
  );
}
