import React from "react";
import { Link } from "react-router-dom";
import EditDeleteBtn from "../home/CommonUtils/EditDeleteBtn";
import { isAdmin } from "../../../utils/helpers";

const endPoint = process.env.REACT_APP_END_POINT;

function ProductCard({ lgWidth, mdWidth, data, wowDelay }) {
  const isAdminUser = isAdmin();

  return (
    <>
      <div
        className={`
        col-lg-${lgWidth || 4} 
        col-md-${mdWidth || 6}  
        col-sm-8 order-1 wow animate fadeInDown
        `}
        data-wow-duration="1.5s"
        data-wow-delay={`${wowDelay}s`}
      >
        <div className="l-work-item">
          <Link
            to={`/solutions/${data?.title.replaceAll(" ", "_")}`}
            onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}
          >
            <img
              src={endPoint + data?.wallImageURL}
              alt="images"
              style={{
                height: lgWidth ? 425 : 320,
              }}
            />

            {isAdminUser && (
              <div className="work-overlay">
                <div className="work-title hover-btn">
                  <span> </span>
                  <i className="bi bi-arrow-90deg-right"></i>
                  <h3>{data?.title}</h3>
                </div>
                <EditDeleteBtn type="product" data={data} />
              </div>
            )}
          </Link>
        </div>
      </div>
    </>
  );
}

export default ProductCard;
