import React, { useState } from "react";
import PaginationArrow from "./PaginationArrow";
import PaginationItem from "./PaginationItem";

function Pagination({
  maxNumOfPaginationItems,
  numOfCardsPerPage,
  page,
  setPage,
  maxCount,
}) {
  const [offset, setOffset] = useState(0);

  return (
    <>
      <div className="row d-flex justify-content-center">
        <div className="col-md-6 text-center">
          <nav aria-label="Page navigation example">
            <ul className="pagination d-flex justify-content-center">
              {offset > 0 && (
                <PaginationArrow
                  direction="prev"
                  page={page}
                  offset={offset}
                  setOffset={setOffset}
                  setPage={setPage}
                />
              )}

              {[...Array(maxNumOfPaginationItems).keys()]
                .slice(offset * 4, (offset + 1) * 4)
                .map((page, index) => {
                  return (
                    <PaginationItem
                      key={index}
                      page={page + 1}
                      setPage={setPage}
                    />
                  );
                })}

              {Math.min(maxNumOfPaginationItems, 4) *
                numOfCardsPerPage *
                (offset + 1) <
                maxCount && (
                <PaginationArrow
                  direction="next"
                  page={page}
                  offset={offset}
                  setOffset={setOffset}
                  setPage={setPage}
                />
              )}
            </ul>
          </nav>
        </div>
      </div>
    </>
  );
}

export default Pagination;
