import React from "react";
import _ from "loadsh";

const endPoint = process.env.REACT_APP_END_POINT;


function Feature(props) {
  const {
    index,
    onChangeFeatureText,
    onDeleteFeatureClick,
    feature,
    handleDataUpload,
  } = props;

  return (
    <section>
      <div className="data-input-custom">
        <h4>Feature</h4>
        <input
          type="text"
          name={`title${index}`}
          id={`title${index}`}
          value={feature?.text}
          onChange={(e) => onChangeFeatureText(e, index, "text")}
        />
      </div>
      <div className="data-input-custom">
        <h4>Icon</h4>
        {/* <input type="number" name="label" id="label" /> */}

        <div className="icon-input-custom">
          <input
            type="file"
            name={`icon${index}`}
            id={`icon${index}`}
            accept="image/*"
            onChange={(e) => handleDataUpload(e, "feature", index)}
          />
          <div className="s-icon">
            <img
              width={80}
              // height={80}
              src={feature.iconUrl}
              // alt="icon"
            />
          </div>
        </div>
      </div>
      <button onClick={(e) => onDeleteFeatureClick(e, index)}>
        Delete Feature
      </button>
    </section>
  );
}

export default Feature;
