import _ from "loadsh";
import React, { Fragment, useEffect, useState } from "react";
import Modal from "react-awesome-modal";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addOrUpdateBlogs } from "../../../../APIs/blogsAPIs";
import { addOrUpdateFeedback } from "../../../../APIs/clientFeedbackAPIs";
import { addOrUpdateNews } from "../../../../APIs/newsAPIs";
import { addOrUpdatePartner } from "../../../../APIs/partnerAPIs";
import { addOrUpdateProduct } from "../../../../APIs/productAPIs";
import { addOrUpdateProject } from "../../../../APIs/projectAPIs";
import { addOrUpdateSolution } from "../../../../APIs/solutionAPIs";
import { postTeam } from "../../../../APIs/teamAPIs";
import { END_POINT } from "../../../../utils/helpers";
import ClientFeedback from "./ClientFeedback";
import Feature from "./Feature";
import Media from "./Media";
import Section from "./Section";
import TeamPartner from "./TeamPartner";
import TextEditor from "./TextEditor";

function DataModal(props) {
    const isAdminUser = true;
    const { type, open, setOpen, data } = props;
    const [loading, setLoading] = useState(false);
    const [date, setDate] = useState(new Date());
    const [dateError, setDateError] = useState("");
    // const type = "solution"; //news  project solution feedback team partner blogs
    const [addClientFB, setAddClientFB] = useState(false);
    // const [visible, setVisible] = useState(open);
    const [sections, setSections] = useState([]);
    const [sectionsError, setSectionsError] = useState("");
    const [features, setFeatures] = useState([]);
    const [featuresError, setFeaturesError] = useState("");
    const [title, setTitle] = useState("");
    const [titleError, setTitleError] = useState("");
    const [solutionType, setSolutionType] = useState("");
    const [solutionTypeError, setSolutionTypeError] = useState("");
    const [breakingNewsType, setBreakingNewsType] = useState("basic");
    const [breakingNewsTypeError, setBreakingNewsTypeError] = useState("");
    const [author, setAuthor] = useState("");
    const [authorError, setAuthorError] = useState("");
    const [clientName, setClientName] = useState("");
    const [clientNameError, setClientNameError] = useState("");
    const [clientFB, setClientFB] = useState("");
    const [clientFBError, setClientFBError] = useState("");
    const [clientTW, setClientTW] = useState("");
    const [clientTWError, setClientTWError] = useState("");
    const [clientIN, setClientIN] = useState("");
    const [clientINError, setClientINError] = useState("");
    const [clientPosition, setClientPosition] = useState("");
    const [clientPositionError, setClientPositionError] = useState("");
    const [feedback, setFeedback] = useState("");
    const [feedbackError, setFeedbackError] = useState("");
    const [stepsTitle, setStepsTitle] = useState("");
    const [stepsTitleError, setStepsTitleError] = useState("");
    const [number, setNumber] = useState(undefined);
    const [numberError, setNumberError] = useState("");
    const [icon, setIcon] = useState(undefined);
    const [iconUrl, setIconUrl] = useState("images/icon/no_img.png");
    const [iconUrlError, setIconUrlError] = useState("");
    const [wallImage, setWallImage] = useState(undefined);
    const [wallImageUrl, setWallImageUrl] = useState("images/icon/no_img.png");
    const [wallImageUrlError, setWallImageUrlError] = useState("");
    const [mainTitle, setMainTitle] = useState("");
    const [mainTitleError, setMainTitleError] = useState("");
    const [summary, setSummary] = useState("");
    const [summaryError, setSummaryError] = useState("");
    const [mainSection, setMainSection] = useState("");
    const [mainSectionError, setMainSectionError] = useState("");
    const [document, setDocument] = useState(undefined);
    const [documentUrl, setDocumentUrl] = useState("");
    const [documentUrlError, setDocumentUrlError] = useState("");
    const [mainMediaType, setMainMediaType] = useState("image");
    const [mainMedia, setMainMedia] = useState();
    const [mainMediaUrl, setMainMediaUrl] = useState("images/icon/no_img.png");
    const [mainMediaUrlError, setMainMediaUrlError] = useState("");
    const [secondMediaType, setSecondMediaType] = useState("image");
    const [secondMedia, setSecondMedia] = useState("");
    const [secondMediaUrl, setSecondMediaUrl] = useState(
        "images/icon/no_img.png"
    );
    const [secondMediaUrlError, setSecondMediaUrlError] = useState("");
    const [thirdMediaType, setThirdMediaType] = useState("image");
    const [thirdMedia, setThirdMedia] = useState("");
    const [thirdMediaUrl, setThirdMediaUrl] = useState(
        "images/icon/no_img.png"
    );
    const [thirdMediaUrlError, setThirdMediaUrlError] = useState("");
    const [clientImage, setClientImage] = useState("");
    const [clientImageUrl, setClientImageUrl] = useState(
        "images/icon/no_img.png"
    );
    const [clientImageUrlError, setClientImageUrlError] = useState("");
    const [steps, setSteps] = useState([]);
    const [stepsError, setStepsError] = useState("");
    const [teamPartnerName, setTeamPartnerName] = useState("");
    const [nameError, setNameError] = useState("");
    const [teamPartnerPosition, setTeamPartnerPosition] = useState("");
    const [teamPartnerPositionError, setTeamPartnerPositionError] =
        useState("");
    const [teamPartnerImageUrl, setTeamPartnerImageUrl] = useState(
        "images/icon/no_img.png"
    );
    const [teamPartnerImageUrlError, setTeamPartnerImageUrlError] =
        useState("");
    const [teamPartnerImage, setTeamPartnerImage] = useState(undefined);
    const [teamPartnerFB, setTeamPartnerFB] = useState("");
    const [FBError, setFBError] = useState("");
    const [teamPartnerTW, setTeamPartnerTW] = useState("");
    const [TWError, setTWError] = useState("");
    const [teamPartnerIN, setTeamPartnerIN] = useState("");
    const [INError, setINError] = useState("");
    const [teamPartnerWeb, setTeamPartnerWeb] = useState("");
    const [WebError, setWebError] = useState("");
    const [clientFeedbackError, setClientFeedbackError] = useState("");
    const [errorMessage, setErrorMessage] = useState("");

    const errorMapper = {
        title: setTitleError,
        type: setSolutionTypeError,
        number: setNumberError,
        mainTitle: setMainTitleError,
        summary: setSummaryError,
        mainSection: setMainSectionError,
        mainMediaURL: setMainMediaUrlError,
        secondMediaURL: setSecondMediaUrlError,
        thirdMediaURL: setThirdMediaUrlError,
        date: setDateError,
        sections: setSectionsError,
        features: setFeaturesError,
        steps: setStepsError,
        clientFeedback: setClientFeedbackError, //for solution modal
        feedback: setClientFeedbackError, //for feedback modal
        name: setNameError,
        position: setClientPositionError,
        fbLink: setFBError,
        twLink: setTWError,
        inLink: setINError,
        webLink: setWebError,
    };

    const clearErrors = () => {
        for (let [_, err] of Object.entries(errorMapper)) err("");
        setErrorMessage("");
    };
    //   const openModal = () => {
    //     setVisible(true);
    //   };
    const setMedia = (mediaURL, setMediaURL, setMediaType, setImage) => {
        setImage(undefined);
        if (!mediaURL || mediaURL?.includes("/images/")) {
            setMediaType("image");
            mediaURL &&
                getFileFromUrl(mediaURL, "image")
                    .then((file) => {
                        setImage(file);
                    })
                    .catch((err) => {});
            setMediaURL(
                mediaURL ? END_POINT + mediaURL : "images/icon/no_img.png"
            );
        } else {
            setMediaType("video");
            setMediaURL(mediaURL);
        }
    };

    async function getFileFromUrl(url, name, defaultType = "image/") {
        const response = await fetch(END_POINT + url);
        if (response.status == 404) throw new Error("filed");
        const data = await response.blob();
        const extension = url.split(".").at(-1);
        return new File([data], name + "." + extension, {
            type: defaultType + extension,
        });
    }

    useEffect(() => {
        clearErrors();

        setTeamPartnerName(data?.name || "");
        setTeamPartnerFB(data?.fbLink || "");
        setTeamPartnerTW(data?.twLink || "");
        setTeamPartnerIN(data?.inLink || "");
        setTeamPartnerWeb(data?.webLink || "");
        setTeamPartnerImage(undefined);
        data?.imageURL &&
            getFileFromUrl(data?.imageURL, "image")
                .then((file) => {
                    setTeamPartnerImage(file);
                })
                .catch((err) => {});
        setTeamPartnerImageUrl(
            data?.imageURL
                ? END_POINT + data?.imageURL
                : "images/icon/no_img.png"
        );

        setClientName(data?.name || "");
        setClientPosition(data?.position || "");
        setFeedback(data?.feedback || "");
        setClientName(data?.name || "");
        setClientFB(data?.fbLink || "");
        setClientTW(data?.twLink || "");
        setClientIN(data?.inLink || "");
        data?.imageURL &&
            getFileFromUrl(data?.imageURL, "image")
                .then((file) => {
                    setClientImage(file);
                })
                .catch((err) => {});
        setClientImageUrl(
            data?.imageURL
                ? END_POINT + data?.imageURL
                : "images/icon/no_img.png"
        );

        try {
            data?.date && setDate(new Date(data?.date));
        } catch (error) {}

        setTitle(data?.title || "");
        setSolutionType(data?.type || "");
        setBreakingNewsType(data?.breakingType || "");

        setWallImage(undefined);
        data?.wallImageURL &&
            getFileFromUrl(data?.wallImageURL, "image")
                .then((file) => {
                    setWallImage(file);
                })
                .catch((err) => {});
        setWallImageUrl(
            data?.wallImageURL
                ? END_POINT + data?.wallImageURL
                : "images/icon/no_img.png"
        );

        setNumber(data?.number || "");

        setIcon(undefined);
        data?.iconURL &&
            getFileFromUrl(data?.iconURL, "icon")
                .then((file) => {
                    setIcon(file);
                })
                .catch((err) => {});
        setIconUrl(
            data?.iconURL ? END_POINT + data?.iconURL : "images/icon/no_img.png"
        );

        setMainTitle(data?.mainTitle || "");
        setSummary(data?.summary || "");
        setMainSection(data?.mainSection || "");

        setDocument(undefined);
        data?.documentURL &&
            getFileFromUrl(data?.documentURL, "document", "application/")
                .then((file) => {
                    // console.log(file);
                    setDocument(file);
                })
                .catch((err) => {});
        setDocumentUrl(data?.documentURL ? END_POINT + data?.documentURL : "");

        setMedia(
            data?.mainMediaURL,
            setMainMediaUrl,
            setMainMediaType,
            setMainMedia
        );
        setMedia(
            data?.secondMediaURL,
            setSecondMediaUrl,
            setSecondMediaType,
            setSecondMedia
        );
        setMedia(
            data?.thirdMediaURL,
            setThirdMediaUrl,
            setThirdMediaType,
            setThirdMedia
        );
        setStepsTitle(data?.steps?.title || "");

        data?.clientFeedback && setAddClientFB(true);

        if (type != "Customer Testimony") {
            setClientName(data?.clientFeedback?.name || "");
            setClientPosition(data?.clientFeedback?.position || "");
            setFeedback(data?.clientFeedback?.feedback || "");
            setClientName(data?.clientFeedback?.name || "");
            setClientFB(data?.clientFeedback?.fbLink || "");
            setClientTW(data?.clientFeedback?.twLink || "");
            setClientIN(data?.clientFeedback?.inLink || "");
            setClientImage(undefined);
            data?.clientFeedback?.imageURL &&
                getFileFromUrl(data?.clientFeedback?.imageURL, "image")
                    .then((file) => {
                        setClientImage(file);
                    })
                    .catch((err) => {});
            setClientImageUrl(
                data?.clientFeedback?.imageURL
                    ? END_POINT + data?.clientFeedback?.imageURL
                    : "images/icon/no_img.png"
            );
        }

        setAuthor(data?.author || "");

        const steps_array = [];
        if (data?.steps?.steps)
            for (let step of data.steps.steps) steps_array.push(step);

        setSteps(steps_array);

        const sections = [];
        if (data?.sections)
            for (let section of data?.sections) {
                const newSection = {
                    sectionType: section?.type,
                    sectionTitle: section?.title || "",
                    sectionText: section?.text || "",
                    sectionMediaType: section?.mediaURL?.includes("/images/")
                        ? "image"
                        : "video",
                    sectionMediaUrl: section?.mediaURL?.includes("/images/")
                        ? END_POINT + section?.mediaURL
                        : section?.mediaURL,
                    sectionMedia: undefined,
                    sectionComment: section?.text || "",
                    sectionCommentBy: section?.author || "",
                };
                if (newSection?.sectionMediaType == "image") {
                    // console.log("hereeeee nowwwwww", section?.mediaURL);
                    section?.mediaURL &&
                        getFileFromUrl(section?.mediaURL, "image")
                            .then((file) => {
                                newSection.sectionMedia = file;
                            })
                            .catch((err) => {});
                }
                sections.push(newSection);
            }
        setSections(sections);

        const features = [];
        if (data?.features)
            for (let feature of data?.features) {
                const newFeature = {
                    text: feature?.text || "",
                    iconUrl: END_POINT + feature?.iconURL,
                    icon: undefined,
                };

                feature?.iconURL &&
                    getFileFromUrl(feature?.iconURL, "image")
                        .then((file) => {
                            newFeature.icon = file;
                        })
                        .catch((err) => {});

                features.push(newFeature);
            }
        setFeatures(features);
    }, [data, open]);

    const closeModal = (e) => {
        e.preventDefault();
        e.stopPropagation();
        setOpen(false);
    };

    const handleRemoveImage = (e, type, index) => {
        e.preventDefault();
        // console.log("hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh");
        switch (type) {
            case "media":
                setMainMedia(undefined);
                setMainMediaUrl("images/icon/no_img.png");
                break;
            case "secondMedia":
                setSecondMedia(undefined);
                setSecondMediaUrl("images/icon/no_img.png");
                break;
            case "thirdMedia":
                setThirdMedia(undefined);
                setThirdMediaUrl("images/icon/no_img.png");
                break;

            case "section":
                const newSections = _.cloneDeep(sections);
                newSections[index].sectionMedia = undefined;
                newSections[index].sectionMediaUrl = "images/icon/no_img.png";
                setSections(newSections);
                break;

            default:
                break;
        }
    };

    const onAddStepsClick = (e) => {
        e.preventDefault();
        const newSteps = _.cloneDeep(steps);
        newSteps.push("");
        setSteps(newSteps);
    };

    const onChangeStepText = (e, index) => {
        // console.log(index, e.target.value);
        const newSteps = _.cloneDeep(steps);
        newSteps[index] = e.target.value;
        setSteps(newSteps);
    };

    const onDeleteStepClick = (e, index) => {
        e.preventDefault();
        const newSteps = _.cloneDeep(steps);
        newSteps.splice(index, 1);
        setSteps(newSteps);
    };

    const onAddSectionClick = (e, type = "section") => {
        e.preventDefault();
        const newSections = _.cloneDeep(sections);
        newSections.push({
            sectionType: type,
            sectionTitle: "",
            sectionText: "",
            sectionMediaType: "image",
            sectionMediaUrl: "images/icon/no_img.png",
            sectionMedia: undefined,
            sectionComment: "",
            sectionCommentBy: "",
        });
        setSections(newSections);
    };

    const onAddFeatureClick = (e) => {
        e.preventDefault();
        const newFeatures = _.cloneDeep(features);
        newFeatures.push({
            text: "",
            iconUrl: "images/icon/no_img.png",
            icon: undefined,
        });
        setFeatures(newFeatures);
    };

    const onDeleteSectionClick = (e, index) => {
        e.preventDefault();
        const newSections = _.cloneDeep(sections);
        newSections.splice(index, 1);
        setSections(newSections);
    };

    const onDeleteFeatureClick = (e, index) => {
        e.preventDefault();
        const newFeatures = _.cloneDeep(features);
        newFeatures.splice(index, 1);
        setFeatures(newFeatures);
    };

    const onChangeSectionText = (newValue, index, key) => {
        const newSections = _.cloneDeep(sections);
        newSections[index][key] = newValue;
        setSections(newSections);
    };

    const onChangeFeatureText = (e, index, key) => {
        const newFeatures = _.cloneDeep(features);
        newFeatures[index][key] = e.target.value;
        setFeatures(newFeatures);
    };

    const onChangeSectionMediaType = (e, index, type) => {
        e.preventDefault();
        const newSections = _.cloneDeep(sections);
        if (type == newSections[index].sectionMediaType) return;
        newSections[index].sectionMediaType = type;
        newSections[index].sectionMediaUrl = "";
        setSections(newSections);
    };
    const handleDataUpload = (e, type, index = 0) => {
        // console.log("here", type);
        const file = e.target.files[0];
        // setImage(file);
        const reader = new FileReader();
        const url = reader.readAsDataURL(file);
        reader.onloadend = () => {
            if (type == "icon") {
                setIconUrl(reader.result);
                setIcon(file);
            } else if (type == "wallImage") {
                setWallImageUrl(reader.result);
                setWallImage(file);
            } else if (type == "media") {
                setMainMedia(file);
                setMainMediaUrl(reader.result);
            } else if (type == "secondMedia") {
                setSecondMedia(file);
                setSecondMediaUrl(reader.result);
            } else if (type == "thirdMedia") {
                setThirdMedia(file);
                setThirdMediaUrl(reader.result);
            } else if (type == "clientImage") {
                setClientImage(file);
                setClientImageUrl(reader.result);
            } else if (type == "teamPartner") {
                setTeamPartnerImage(file);
                setTeamPartnerImageUrl(reader.result);
            } else if (type == "section") {
                const newSections = _.cloneDeep(sections);
                newSections[index].sectionMediaUrl = reader.result;
                newSections[index].sectionMedia = file;
                setSections(newSections);
            } else if (type == "feature") {
                const newFeatures = _.cloneDeep(features);
                newFeatures[index].iconUrl = reader.result;
                newFeatures[index].icon = file;
                setFeatures(newFeatures);
            } else {
                setDocument(file);
                setDocumentUrl(reader.result);
            }
        };
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        const formData = new FormData();

        const handleUploadImage = (image, imageURL, key, urlKey) => {
            image
                ? formData.append(key, image)
                : imageURL &&
                  imageURL != "images/icon/no_img.png" &&
                  formData.append(urlKey, imageURL);
        };

        const handleMediaUpload = (type, media, mediaURL, key, urlKey) => {
            if (type == "image")
                handleUploadImage(media, mediaURL, key, urlKey);
            else mediaURL && formData.append(urlKey, mediaURL);
        };

        number && formData.append("number", number);

        if (type == "team" || type == "partner") {
            teamPartnerName && formData.append("name", teamPartnerName); //team and partner
            teamPartnerPosition &&
                formData.append("position", teamPartnerPosition); //team and partner
            teamPartnerImage && formData.append("image", teamPartnerImage);
            teamPartnerFB && formData.append("fbLink", teamPartnerFB); //team and partner
            teamPartnerIN && formData.append("inLink", teamPartnerIN); //team and partner
            teamPartnerTW && formData.append("twLink", teamPartnerTW); //team and partner
            teamPartnerWeb && formData.append("webLink", teamPartnerWeb); //team and partner
        }

        author && formData.append("author", author); //news

        title && formData.append("title", title); //solution, project and news
        solutionType && formData.append("type", solutionType); //solution , product
        formData.append("breakingType", breakingNewsType); //news

        mainTitle && formData.append("mainTitle", mainTitle); //solution, project and news
        mainSection && formData.append("mainSection", mainSection); //solution, project and news
        date &&
            formData.append(
                "date",
                date.toISOString().slice(0, 10) +
                    new Date(Date.now()).toISOString().slice(10)
            ); //solution, project and news

        mainMediaType == "image" &&
            mainMedia &&
            formData.append("mainMedia", mainMedia); //solution, project and news

        mainMediaType == "video" &&
            mainMediaUrl &&
            formData.append("mainMediaURL", mainMediaUrl); //solution, project and news

        // handleMediaUpload(mainMediaType, mainMedia, mainMediaUrl, "mainMedia", "mainMediaURL");

        summary && formData.append("summary", summary); //solution
        icon && formData.append("icon", icon); //solution
        document && formData.append("document", document); //solution

        secondMediaType == "image" &&
            secondMedia &&
            formData.append("secondMedia", secondMedia); //project

        secondMediaType == "video" &&
            secondMediaUrl &&
            formData.append("secondMediaURL", secondMediaUrl); //project

        thirdMediaType == "image" &&
            thirdMedia &&
            formData.append("thirdMedia", thirdMedia); //project

        thirdMediaType == "video" &&
            thirdMediaUrl &&
            formData.append("thirdMediaURL", thirdMediaUrl); //project

        wallImage && formData.append("wallImage", wallImage); // project and news

        if (type == "Customer Testimony") {
            clientImage && formData.append("image", clientImage); //feedback
            clientName && formData.append("name", clientName); //project
            clientFB && formData.append("fbLink", clientFB); //project
            clientTW && formData.append("twLink", clientTW); //project
            clientIN && formData.append("inLink", clientIN); //project
            clientPosition && formData.append("position", clientPosition); //project
            feedback && formData.append("feedback", feedback); //project
        } else if (addClientFB && (type == "project" || type == "product")) {
            clientImage && formData.append("clientFeedbackImage", clientImage); //project
            clientName && formData.append("clientFeedback[name]", clientName); //project
            clientFB && formData.append("clientFeedback[fbLink]", clientFB); //project
            clientTW && formData.append("clientFeedback[twLink]", clientTW); //project
            clientIN && formData.append("clientFeedback[inLink]", clientIN); //project
            clientPosition &&
                formData.append("clientFeedback[position]", clientPosition); //project
            feedback && formData.append("clientFeedback[feedback]", feedback); //project
        }

        stepsTitle && formData.append("steps[title]", stepsTitle); //project
        steps &&
            steps.forEach((step, index) => {
                formData.append(`steps[steps][${index}]`, step); //project
            });

        sections.forEach((section, index) => {
            section.sectionTitle &&
                formData.append(
                    `sections[${index}][title]`,
                    section.sectionTitle
                );

            formData.append(`sections[${index}][type]`, section.sectionType);
            formData.append(
                `sections[${index}][text]`,
                section.sectionText || section.sectionComment
            );
            section.sectionCommentBy &&
                formData.append(
                    `sections[${index}][author]`,
                    section.sectionCommentBy
                );

            section.sectionMediaType == "image" &&
                section.sectionMedia &&
                formData.append(`sectionMedia_${index}`, section.sectionMedia);

            section.sectionMediaType == "video" &&
                section.sectionMediaUrl &&
                formData.append(
                    `sections[${index}][mediaURL]`,
                    section.sectionMediaUrl
                );
        });

        features.forEach((feature, index) => {
            feature.text &&
                formData.append(`features[${index}][text]`, feature.text);

            feature.iconUrl &&
                formData.append(`featureIcon_${index}`, feature.icon);
        });

        let res;
        if (type == "solution")
            res = await addOrUpdateSolution(formData, data?._id);
        if (type == "project")
            res = await addOrUpdateProject(formData, data?._id);
        if (type == "team") res = await postTeam(formData, data?._id);
        if (type == "partner")
            res = await addOrUpdatePartner(formData, data?._id);
        if (type == "news") res = await addOrUpdateNews(formData, data?._id);
        if (type == "blogs") res = await addOrUpdateBlogs(formData, data?._id);
        if (type == "Customer Testimony")
            res = await addOrUpdateFeedback(formData, data?._id);
        if (type == "product")
            res = await addOrUpdateProduct(formData, data?._id);

        clearErrors();

        if (res?.success != undefined) setLoading(false);

        if (res?.success) {
            setOpen(false);
            return;
        } else {
            setErrorMessage(res?.message);
            if (res?.message == "Validation error!") {
                for (let err of res?.validators) {
                    // console.log(err, errorMapper[err?.param], err?.msg);
                    errorMapper[err?.param](err?.msg);
                }
            }
        }
    };
    return (
        <section>
            <Modal
                visible={open}
                // width="80%"
                // height="80%"
                className="modal-custom"
                effect="fadeInUp"
                // style={{}}
                onClickAway={closeModal}
            >
                <div className="modal-custom">
                    <h1 style={{ color: "white" }}>Add {type}</h1>
                    <hr style={{ color: "white" }} />
                    <form action="">
                        {type == "team" || type == "partner" ? (
                            <TeamPartner
                                teamPartnerName={teamPartnerName}
                                teamPartnerNameError={nameError}
                                setTeamPartnerName={setTeamPartnerName}
                                teamPartnerPosition={teamPartnerPosition}
                                teamPartnerPositionError={
                                    teamPartnerPositionError
                                }
                                setTeamPartnerPosition={setTeamPartnerPosition}
                                handleDataUpload={handleDataUpload}
                                teamPartnerImageUrl={teamPartnerImageUrl}
                                teamPartnerImageUrlError={
                                    teamPartnerImageUrlError
                                }
                                teamPartnerFB={teamPartnerFB}
                                teamPartnerFBError={FBError}
                                setTeamPartnerFB={setTeamPartnerFB}
                                teamPartnerTW={teamPartnerTW}
                                teamPartnerTWError={TWError}
                                setTeamPartnerTW={setTeamPartnerTW}
                                teamPartnerIN={teamPartnerIN}
                                teamPartnerINError={INError}
                                setTeamPartnerIN={setTeamPartnerIN}
                                teamPartnerWeb={teamPartnerWeb}
                                teamPartnerWebError={WebError}
                                setTeamPartnerWeb={setTeamPartnerWeb}
                                number={number}
                                numberError={numberError}
                                setNumber={setNumber}
                                type={type}
                            />
                        ) : type == "Customer Testimony" ? (
                            <ClientFeedback
                                clientNameFB={clientName}
                                clientNameError={nameError}
                                setClientNameFB={setClientName}
                                clientPosition={clientPosition}
                                clientPositionError={clientPositionError}
                                setClientPosition={setClientPosition}
                                handleDataUpload={handleDataUpload}
                                clientImageUrl={clientImageUrl}
                                clientImageUrlError={clientImageUrlError}
                                feedback={feedback}
                                feedbackError={clientFeedbackError}
                                setFeedback={setFeedback}
                                type={type}
                                number={number}
                                numberError={numberError}
                                setNumber={setNumber}
                                clientFB={clientFB}
                                clientFBError={FBError}
                                setClientFB={setClientFB}
                                clientIN={clientIN}
                                clientINError={INError}
                                setClientIN={setClientIN}
                                clientTW={clientTW}
                                clientTWError={TWError}
                                setClientTW={setClientTW}
                            />
                        ) : (
                            <section>
                                <div className="data-input-custom">
                                    <h4>Title</h4>
                                    <input
                                        type="text"
                                        name="label"
                                        id="label"
                                        value={title}
                                        onChange={(e) =>
                                            setTitle(e.target.value)
                                        }
                                    />
                                    <p>{titleError}</p>
                                </div>

                                {type == "solution" || type == "product" ? (
                                    <div className="data-input-custom">
                                        <label for="type" name="label">
                                            <h4>Type</h4>
                                        </label>
                                        <select
                                            id="type"
                                            value={solutionType}
                                            onChange={(e) =>
                                                setSolutionType(e.target.value)
                                            }
                                        >
                                            <option value="" selected>
                                                --select type--
                                            </option>
                                            <option value="mobility">
                                                mobility
                                            </option>
                                            <option value="security">
                                                security
                                            </option>
                                        </select>
                                        <p>{solutionTypeError}</p>
                                    </div>
                                ) : null}

                                {type == "news" ? (
                                    <div className="data-input-custom">
                                        <label for="type" name="label">
                                            <h4>Type</h4>
                                        </label>
                                        <select
                                            id="type"
                                            value={breakingNewsType}
                                            onChange={(e) =>
                                                setBreakingNewsType(
                                                    e.target.value
                                                )
                                            }
                                        >
                                            <option value="" selected>
                                                --select breaking news type--
                                            </option>
                                            <option value="breaking">
                                                breaking
                                            </option>
                                            <option value="basic">basic</option>
                                        </select>
                                        <p>{breakingNewsTypeError}</p>
                                    </div>
                                ) : null}

                                <div className="data-input-custom">
                                    <h4>Number</h4>
                                    <input
                                        type="number"
                                        name="number"
                                        value={number}
                                        onChange={(e) =>
                                            setNumber(e.target.value)
                                        }
                                        id="number"
                                        min={1}
                                    />
                                    <p>{numberError}</p>
                                </div>
                                <div className="data-input-custom">
                                    <h4>
                                        {type == "solution"
                                            ? "Icon"
                                            : "Wall Image"}
                                    </h4>
                                    <div className="icon-input-custom">
                                        <input
                                            type="file"
                                            name="icon"
                                            id="icon"
                                            onChange={(e) =>
                                                type == "solution"
                                                    ? handleDataUpload(
                                                          e,
                                                          "icon"
                                                      )
                                                    : handleDataUpload(
                                                          e,
                                                          "wallImage"
                                                      )
                                            }
                                            accept="image/*"
                                        />
                                        <div className="s-icon">
                                            <img
                                                width={80}
                                                height={80}
                                                src={
                                                    type == "solution"
                                                        ? iconUrl
                                                        : wallImageUrl
                                                }
                                            />
                                        </div>
                                    </div>
                                </div>
                                <div className="data-input-custom">
                                    <h4>Main Title</h4>
                                    <input
                                        type="text"
                                        name="mainTitle"
                                        value={mainTitle}
                                        onChange={(e) =>
                                            setMainTitle(e.target.value)
                                        }
                                        id="mainTitle"
                                    />
                                    <p>{mainTitleError}</p>
                                </div>
                                {type == "solution" || type == "product" ? (
                                    <div className="data-input-custom">
                                        <h4>Summary</h4>
                                        <textarea
                                            name="summary"
                                            id="summary"
                                            cols="30"
                                            rows="3"
                                            value={summary}
                                            onChange={(e) =>
                                                setSummary(e.target.value)
                                            }
                                        ></textarea>
                                        <p>{summaryError}</p>
                                    </div>
                                ) : null}

                                {/* <div className="data-input-custom">
                  <h4>Main Section</h4>
                  <textarea
                    name="mainSection"
                    id="mainSection"
                    value={mainSection}
                    onChange={(e) => setMainSection(e.target.value)}
                    cols="30"
                    rows="3"
                  ></textarea>
                  <p>{mainSectionError}</p>
                </div> */}

                                <div className="data-input-custom">
                                    <h4>Main Section</h4>
                                    <div
                                        name="mainSection"
                                        id="mainSection"
                                        className="text-editor"
                                    >
                                        <TextEditor
                                            text={mainSection}
                                            setText={setMainSection}
                                        />
                                    </div>
                                    <p>{mainSectionError}</p>
                                </div>
                                {type == "solution" || type == "product" ? (
                                    <div className="data-input-custom">
                                        <h4>Document</h4>
                                        <div className="icon-input-custom">
                                            <input
                                                type="file"
                                                name="document"
                                                id="document"
                                                accept="application/pdf"
                                                onChange={(e) =>
                                                    handleDataUpload(e, "")
                                                }
                                            />
                                            <div
                                                className="cursor-pointer"
                                                style={{
                                                    width: "80px",
                                                    cursor: "pointer",
                                                }}
                                            >
                                                <a
                                                    target="_blank"
                                                    className="cursor-pointer"
                                                    href={documentUrl}
                                                >
                                                    {documentUrl}
                                                </a>
                                            </div>
                                            {/* <img
                          width={80}
                          height={80}
                          src={type == "solution" ? iconUrl : wallImageUrl}
                        />
                      </div> */}
                                        </div>
                                        <p>{documentUrlError}</p>
                                    </div>
                                ) : null}

                                <Media
                                    type={mainMediaType}
                                    setType={setMainMediaType}
                                    name="mainMedia"
                                    error={mainMediaUrlError}
                                    url={mainMediaUrl}
                                    setUrl={setMainMediaUrl}
                                    handleDataUpload={handleDataUpload}
                                    handleRemoveImage={handleRemoveImage}
                                    uploadType="media"
                                    title="Main Media"
                                />
                                {type == "project" || type == "product" ? (
                                    <React.Fragment>
                                        <Media
                                            type={secondMediaType}
                                            setType={setSecondMediaType}
                                            name="secondMedia"
                                            url={secondMediaUrl}
                                            error={secondMediaUrlError}
                                            setUrl={setSecondMediaUrl}
                                            handleDataUpload={handleDataUpload}
                                            handleRemoveImage={
                                                handleRemoveImage
                                            }
                                            uploadType="secondMedia"
                                            title="Second Media"
                                        />
                                        <Media
                                            type={thirdMediaType}
                                            setType={setThirdMediaType}
                                            name="thirdMedia"
                                            error={thirdMediaUrlError}
                                            url={thirdMediaUrl}
                                            setUrl={setThirdMediaUrl}
                                            handleDataUpload={handleDataUpload}
                                            handleRemoveImage={
                                                handleRemoveImage
                                            }
                                            uploadType="thirdMedia"
                                            title="Third Media"
                                        />
                                        {/* <div className="data-input-custom">
                      <h4>Steps Title (optional)</h4>
                      <input
                        type="text"
                        name="stepsTitle"
                        value={stepsTitle}
                        onChange={(e) => setStepsTitle(e.target.value)}
                        id="stepsTitle"
                      />
                    </div>
                    {steps.map((step, index) => (
                      <section key={index}>
                        <div className="data-input-custom">
                          <h4>Step</h4>
                          <input
                            type="text"
                            name={`step${index}`}
                            value={step}
                            onChange={(e) => onChangeStepText(e, index)}
                            id={`step${index}`}
                          />
                        </div>
                        <button onClick={(e) => onDeleteStepClick(e, index)}>
                          Delete Step
                        </button>
                      </section>
                    ))}
                    <button onClick={onAddStepsClick}>Add Step</button>
                    <p className="error-msg">{stepsError}</p> */}
                                        <div className="data-input-custom">
                                            <h4>Add Customer Testimony?</h4>
                                            <div className="add-section-comment">
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAddClientFB(true);
                                                    }}
                                                    className={
                                                        addClientFB == true
                                                            ? "selected-button"
                                                            : ""
                                                    }
                                                >
                                                    Yes
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setAddClientFB(false);
                                                    }}
                                                    className={
                                                        addClientFB == true
                                                            ? ""
                                                            : "selected-button"
                                                    }
                                                >
                                                    No
                                                </button>
                                            </div>
                                        </div>
                                        {addClientFB == true ? (
                                            <Fragment>
                                                <ClientFeedback
                                                    clientNameFB={clientName}
                                                    clientNameError={
                                                        clientNameError
                                                    }
                                                    setClientNameFB={
                                                        setClientName
                                                    }
                                                    clientPosition={
                                                        clientPosition
                                                    }
                                                    clientPositionError={
                                                        clientPositionError
                                                    }
                                                    setClientPosition={
                                                        setClientPosition
                                                    }
                                                    handleDataUpload={
                                                        handleDataUpload
                                                    }
                                                    clientImageUrl={
                                                        clientImageUrl
                                                    }
                                                    clientImageUrlError={
                                                        clientImageUrlError
                                                    }
                                                    feedback={feedback}
                                                    feedbackError={
                                                        feedbackError
                                                    }
                                                    setFeedback={setFeedback}
                                                    clientFB={clientFB}
                                                    clientFBError={
                                                        clientFBError
                                                    }
                                                    setClientFB={setClientFB}
                                                    clientIN={clientIN}
                                                    clientINError={
                                                        clientINError
                                                    }
                                                    setClientIN={setClientIN}
                                                    clientTW={clientTW}
                                                    clientTWError={
                                                        setClientTWError
                                                    }
                                                    setClientTW={setClientTW}
                                                />
                                                <p className="error-msg">
                                                    {clientFeedbackError}
                                                </p>
                                            </Fragment>
                                        ) : null}
                                    </React.Fragment>
                                ) : null}
                                {type == "news" || type == "blogs" ? (
                                    <div className="data-input-custom">
                                        <h4>Author</h4>
                                        <input
                                            type="text"
                                            name="author"
                                            value={author}
                                            onChange={(e) =>
                                                setAuthor(e.target.value)
                                            }
                                            id="author"
                                        />
                                        <p>{authorError}</p>
                                    </div>
                                ) : null}
                                <div className="data-input-custom none-background">
                                    <h4>Date</h4>
                                    <div style={{ width: "300px" }}>
                                        <DatePicker
                                            style
                                            selected={date}
                                            onChange={(date) => setDate(date)}
                                        />
                                    </div>
                                    <p>{dateError}</p>
                                </div>

                                {sections.map((section, index) => (
                                    <Section
                                        index={index}
                                        key={index}
                                        onDeleteSectionClick={
                                            onDeleteSectionClick
                                        }
                                        onChangeSectionText={
                                            onChangeSectionText
                                        }
                                        section={section}
                                        onChangeSectionMediaType={
                                            onChangeSectionMediaType
                                        }
                                        handleDataUpload={handleDataUpload}
                                        handleRemoveImage={handleRemoveImage}
                                    />
                                ))}
                                <p className="error-msg">{sectionsError}</p>

                                <div className="add-section-comment">
                                    <button onClick={onAddSectionClick}>
                                        Add Section
                                    </button>
                                    <button
                                        onClick={(e) =>
                                            onAddSectionClick(e, "comment")
                                        }
                                    >
                                        Add Comment
                                    </button>
                                </div>

                                {features.map((feature, index) => (
                                    <Feature
                                        index={index}
                                        onDeleteFeatureClick={
                                            onDeleteFeatureClick
                                        }
                                        onChangeFeatureText={
                                            onChangeFeatureText
                                        }
                                        feature={feature}
                                        handleDataUpload={handleDataUpload}
                                    />
                                ))}
                                <p className="error-msg">{featuresError}</p>
                                {features?.length < 4 && type == "product" ? (
                                    <div className="add-section-comment">
                                        <button onClick={onAddFeatureClick}>
                                            Add Feature
                                        </button>
                                    </div>
                                ) : null}
                            </section>
                        )}
                        <div className="add-section-comment">
                            {loading ? (
                                <>
                                    <button class="btn" disabled>
                                        <span
                                            class="spinner-border"
                                            role="status"
                                            aria-hidden="true"
                                            style={{
                                                width: "25px",
                                                height: "25px",
                                                marginRight: "10px",
                                            }}
                                        ></span>
                                        <span class="sr-only">Submit</span>
                                    </button>
                                </>
                            ) : (
                                <>
                                    <button
                                        type="submit"
                                        onClick={(e) => handleSubmit(e)}
                                    >
                                        Submit
                                    </button>
                                </>
                            )}

                            <button onClick={closeModal}>Cancel</button>
                        </div>
                        <p className="error-msg">{errorMessage}</p>
                    </form>
                </div>
            </Modal>
        </section>
    );
}

export default DataModal;
