import React from "react";
import AddOrAllSection from "./CommonUtils/AddOrAllSection";
import FeedbackCard from "./FeedbackUtils/FeedbackCardNew";

function FeedbackArea(props) {
    const { showModal, data } = props;

    return (
        <>
            <div className="testimonial-section pt-120">
                <div className="container">
                    {/* <div className="row d-flex justify-content-lg-end justify-content-center"> */}
                    <div className="row d-flex justify-content-lg-start justify-content-center">
                        <div className="col-md-8 text-center">
                            <div
                                className="section-title pt-0 wow animate fadeInDown"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.5s"
                            >
                                <h2>Customer Testimonies</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row d-flex justify-content-center g-4">
                        {/* <div className="col-lg-4 col-md-8 col-sm-8 order-lg-1 order-2">
              <div
                className="section-card testimonial-section-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <AddOrAllSection
                  showModal={showModal}
                  link="feedback"
                  type="feedback"
                />
              </div>
            </div> */}
                        {/* <div className="col-lg-8 order-lg-2 order-1"> */}
                        <div className="col-lg-8 order-1">
                            {data?.map((feedback, index) => {
                                return (
                                    <FeedbackCard key={index} data={feedback} />
                                );
                            })}
                        </div>

                        {/* <div className="col-lg-4 col-md-8 col-sm-8 order-lg-1 order-2"> */}
                        <div className="col-lg-4 col-md-8 col-sm-8 order-2">
                            <div
                                className="section-card testimonial-section-card wow animate fadeInUp"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.5s"
                            >
                                <AddOrAllSection
                                    showModal={showModal}
                                    link="customer-testimonies"
                                    type="Customer Testimony"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeedbackArea;
