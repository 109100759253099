import React from "react";
import SectionTitle from "../../common/SectionTitle";
import { isAdmin } from "../../../utils/helpers";
import TeamPartnerCard from "../home/TeamPartnerUtils/TeamPartnerCard";
import AddOrAllSection from "../home/CommonUtils/AddOrAllSection";
import Spinner from "../../common/Spinner";

function PartnerWrap({ data }) {
  const isAdminUser = isAdmin();

  return (
    <>
      <div className="team-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <SectionTitle title="Our Partners" />
          </div>

          <div className="row row-cols-2 row-cols-sm-3 row-cols-md-4 row-cols-lg-5 row-cols-xl-5 d-flex justify-content-center g-4">
            {data ? (
              <>
                {data?.map((partner, index) => {
                  return (
                    <TeamPartnerCard
                      key={index}
                      data={partner}
                      teamOrPartner="partner"
                      colWidth="col"
                      data-wow-delay="0.5"
                    />
                  );
                })}

                {isAdminUser && (
                  <div className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown">
                    <div
                      className="section-card team-card"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <AddOrAllSection link="partners" type="partner" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default PartnerWrap;
