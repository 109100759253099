import axios from "axios";

const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const postTeam = async (formData) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.post(`${endPoint}/teams`, formData, auth);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};
