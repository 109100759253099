import React, { useState } from "react";
import { Link } from "react-router-dom";
import SectionTitle from "../../common/SectionTitle";
import { sendEmail } from "../../../APIs/contactUsAPIs";

function ContactWrap() {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [subject, setSubject] = useState("");
  const [subjectError, setSubjectError] = useState("");
  const [message, setMessage] = useState("");
  const [messageError, setMessageError] = useState("");
  const [resError, setResError] = useState("");
  const [loading, setLoading] = useState(false);

  const [emailSent, setEmailSent] = useState(false);

  const errorMapper = {
    email: setEmailError,
    subject: setSubjectError,
    message: setMessageError,
  };

  const resetErrors = () => {
    setEmailError("");
    setSubjectError("");
    setMessageError("");
    setResError("");
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    resetErrors();
    setLoading(true);
    const res = await sendEmail({ email, subject, message });
    setLoading(false);
    if (!res.success) {
      setResError(res?.message);
      if (res?.validators) {
        for (let validator of res?.validators) {
          errorMapper[validator?.param](validator?.msg);
        }
      }
    } else {
      setEmailSent(true);
    }
  };
  const scrolltop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="contact-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <SectionTitle title="Contact Us" />
          </div>
          <div className="row pb-120 g-4 d-flex justify-content-center">
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bi bi-geo-alt"></i>
                </div>
                <div className="text">
                  <h3>Location</h3>
                  <h4>Office 1107, JLT, Dubai, UAE </h4>
                  {/* <h5>Dubai, United Arab Emirates</h5> */}
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bx bx-phone-call"></i>
                </div>
                <div className="text">
                  <h3>Phone</h3>
                  <a href="tel:+9714 264 2828">
                    <h4>+9714 264 2828</h4>
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-md-6 col-sm-8">
              <div className="eg-card contact-signle hover-btn">
                <span></span>
                <div className="icon">
                  <i className="bx bx-envelope"></i>
                </div>
                <div className="text">
                  <h3>Email</h3>
                  <a href="mailto:Info@com-iot.com">
                    <h4>Info@com-iot.com</h4>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <SectionTitle
              title={
                emailSent ? "Thank You For Your Email!" : "Get In Touch..."
              }
            />
          </div>
          {emailSent ? null : (
            <div className="row d-flex justify-content-center">
              <div className="col-lg-8 text-center">
                <form
                  onSubmit={onSubmit}
                  className="contact-form contact-form-updated"
                >
                  <div className="row">
                    <div className="col-md-6">
                      <div className="form-inner">
                        <input
                          type="email"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          required
                          placeHolder="Enter Your Email :"
                        />
                        <p className="error">{emailError}</p>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-inner">
                        <input
                          type="text"
                          required
                          placeHolder="Subject :"
                          value={subject}
                          onChange={(e) => setSubject(e.target.value)}
                        />
                        <p className="error">{subjectError}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-inner">
                        <textarea
                          value={message}
                          onChange={(e) => setMessage(e.target.value)}
                          placeHolder="Enter Your Message"
                        ></textarea>
                        <p className="error">{messageError}</p>
                      </div>
                    </div>
                    <div className="col-md-12">
                      {loading ? (
                        <>
                          <button
                            class="eg-btn hover-btn comment-form-btn"
                            disabled
                          >
                            <span
                              class="spinner-border"
                              role="status"
                              aria-hidden="true"
                              style={{
                                width: "25px",
                                height: "25px",
                                marginRight: "10px",
                                display: "inline-block",
                              }}
                            ></span>
                            <span style={{ display: "inline" }}>Submit</span>
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="eg-btn hover-btn comment-form-btn"
                            type="submit"
                          >
                            <span>Send Now</span>
                          </button>
                        </>
                      )}
                      <p className="error">{resError}</p>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export default ContactWrap;
