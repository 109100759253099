import React from "react";
import AchivementArea from "../../common/AchivementArea";
import Breadcrumb from "../../common/Breadcrumb";
import JoinUs from "../../common/JoinUs";
import Sponsor from "../../common/Sponsor";
import Testimonial from "../../common/Testimonial";
import AboutArea from "../home/AboutArea";
import AboutWrap from "./AboutWrap";
import { Context } from "../../App";
import { useEffect, useContext } from "react";
import SectionTitle from "../../common/SectionTitle";

function AboutPage() {
  const { reloadAnimation } = useContext(Context);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  useEffect(() => {
    scrollTop();
    reloadAnimation();
  }, []);
  return (
    <>
      <Breadcrumb name="About Us" />
      <div className="pt-120" />
      <div className="container">
        <div className="row d-flex justify-content-lg-start justify-content-center">
          <SectionTitle title="About Us" />
        </div>
      </div>
      <AboutArea />
      {/* <AboutWrap /> */}
      {/* <AchivementArea /> */}
      {/* <Testimonial /> */}
      {/* <JoinUs padding="pt-120" /> */}
      {/* <Sponsor /> */}
    </>
  );
}

export default AboutPage;
