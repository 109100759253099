import React, { useState, useEffect, useContext } from "react";
import Breadcrumb from "../../common/Breadcrumb";
import JoinUsPartner from "./JoinUsPartner";
import Sponsor from "./Sponsor";
import PartnerWrap from "./PartnerWrap";
import { getAllPartners } from "../../../APIs/partnerAPIs";
import { Context } from "../../App";

function PartnerPage() {
  const [allPartners, setAllPartners] = useState();

  const { reloadAnimation } = useContext(Context);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(async () => {
    scrollTop();
    const data = await getAllPartners();
    setAllPartners(data?.partners);
    reloadAnimation();
  }, []);

  return (
    <>
      <Breadcrumb name="Our Partners" />
      <PartnerWrap data={allPartners} />
      {/* <JoinUsPartner /> */}
      {/* <Sponsor /> */}
    </>
  );
}

export default PartnerPage;
