import React, { useState, useEffect, useContext } from "react";
import BlogCard from "./BlogCard";
import Pagination from "../../common/Pagination";
import { getAllNews } from "../../../APIs/newsAPIs";
import SectionTitle from "../../common/SectionTitle";
import Spinner from "../../common/Spinner";
import { Context } from "../../App";
import AddOrAllSection from "../home/CommonUtils/AddOrAllSection";
import { isAdmin } from "../../../utils/helpers";

function NewsGridWrap() {
  const isAdminUser = isAdmin();

  const [allNews, setAllNews] = useState();
  const [maxCount, setMaxCount] = useState();
  const [maxNumOfPaginationItems, setMaxNumOfPaginationItems] = useState();
  const [page, setPage] = useState(1);
  const numOfCardsPerPage = 10;

  const { reloadAnimation } = useContext(Context);
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(async () => {
    scrollTop();
    const data = await getAllNews(numOfCardsPerPage, page);
    setAllNews(data?.news);
    setMaxCount(data?.maxCount);
    setMaxNumOfPaginationItems(Math.ceil(data?.maxCount / numOfCardsPerPage));
    reloadAnimation();
  }, []);

  useEffect(async () => {
    const data = await getAllNews(numOfCardsPerPage, page);
    setAllNews(data?.news);
    setMaxCount(data?.maxCount);
    setMaxNumOfPaginationItems(Math.ceil(data?.maxCount / numOfCardsPerPage));
  }, [page]);
  return (
    <>
      <div className="blog-grid-section pt-120 pb-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <SectionTitle title="Our News" />
          </div>
          {allNews ? (
            <div className="row d-flex justify-content-center g-4">
              {allNews?.map((newsItem, index) => {
                return (
                  <div className="col-lg-6 col-md-8 col-sm-10">
                    <BlogCard
                      data={newsItem}
                      key={index}
                      link="news"
                      type="news"
                    />
                  </div>
                );
              })}
              <>
                {isAdminUser && (
                  <div className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown">
                    <div
                      className="section-card team-card"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <AddOrAllSection link="news" type="news" />
                    </div>
                  </div>
                )}
              </>
            </div>
          ) : (
            <Spinner />
          )}

          {numOfCardsPerPage < maxCount && (
            <Pagination
              maxNumOfPaginationItems={maxNumOfPaginationItems}
              numOfCardsPerPage={numOfCardsPerPage}
              maxCount={maxCount}
              page={page}
              setPage={setPage}
            />
          )}
        </div>
      </div>
    </>
  );
}

export default NewsGridWrap;
