import React from "react";
import EditDeleteBtn from "../home/CommonUtils/EditDeleteBtn";
import SocialMediaLinks from "../home/CommonUtils/SocialMediaLinks";

const endPoint = process.env.REACT_APP_END_POINT;

function FeedbackSingleItem({ data }) {
    return (
        <>
            <div className="col-md-6 col-sm-8 wow animation fadeInDown">
                <div
                    className="eg-card testimonial-item d-flex justify-content-sm-start justify-content-center align-items-center flex-sm-nowrap flex-wrap hover-btn"
                    style={{ marginBottom: "20px" }}
                >
                    <div
                        style={{
                            width: "70%",
                            alignSelf: "stretch",
                            position: "relative",
                        }}
                    >
                        <div
                            className="styled-text para"
                            dangerouslySetInnerHTML={{
                                __html: data?.feedback.replace(/\n/g, "<br>"),
                            }}
                            style={{ marginBottom: "20px" }}
                        />

                        <img
                            src={
                                process.env.PUBLIC_URL +
                                "/images/icon/quote.svg"
                            }
                            style={{ display: "block", marginLeft: "40px" }}
                            alt="images"
                        />

                        <div
                            className="testimonial-footer d-flex justify-content-between align-items-end"
                            style={{
                                position: "relative",
                                bottom: "10px",
                                width: "100%",
                            }}
                        >
                            <SocialMediaLinks
                                fbLink={data?.fbLink}
                                twLink={data?.twLink}
                                inLink={data?.inLink}
                                cssClassName="social-media d-flex"
                            />

                            <div className="author">
                                <h5>{data?.name}</h5>
                                <p> {data?.position} </p>
                            </div>
                        </div>
                        <EditDeleteBtn
                            data={data}
                            type="Customer Testimony"
                            color="white"
                            someWidth
                        />
                    </div>
                    <div style={{ width: "30%" }}>
                        <div>
                            {data?.imageURL ? (
                                <img
                                    src={endPoint + data?.imageURL}
                                    alt="images"
                                    style={{ width: "80%" }}
                                />
                            ) : (
                                <i
                                    class="bi bi-person-circle"
                                    style={{ fontSize: 100 }}
                                ></i>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FeedbackSingleItem;
