import React from "react";
import Breadcrumb from "../../common/Breadcrumb";
import LoginWrap from "./LoginWrap";

export default function index() {
  return (
    <>
      <Breadcrumb name="Login" />
      <LoginWrap />
    </>
  );
}
