import React from "react";

const endPoint = process.env.REACT_APP_END_POINT;


function TeamPartner(props) {
  const {
    teamPartnerName,
    teamPartnerNameError,
    setTeamPartnerName,
    teamPartnerPosition,
    teamPartnerPositionError,
    setTeamPartnerPosition,
    handleDataUpload,
    teamPartnerImageUrl,
    teamPartnerImageUrlError,
    teamPartnerFB,
    teamPartnerFBError,
    setTeamPartnerFB,
    teamPartnerTW,
    teamPartnerTWError,
    setTeamPartnerTW,
    teamPartnerIN,
    teamPartnerINError,
    setTeamPartnerIN,
    teamPartnerWeb,
    teamPartnerWebError,
    setTeamPartnerWeb,
    type,
    number,
    numberError,
    setNumber,
  } = props;
  return (
    <section>
      <div className="data-input-custom">
        <h4>Name</h4>
        <input
          type="text"
          name="teamPartnerName"
          value={teamPartnerName}
          onChange={(e) => setTeamPartnerName(e.target.value)}
          id="teamPartnerName"
        />
        <p>{teamPartnerNameError}</p>
      </div>
      <div className="data-input-custom">
        <h4>Number</h4>
        <input
          type="number"
          name="number"
          value={number}
          onChange={(e) => setNumber(e.target.value)}
          id="number"
          min={1}
        />
        <p>{numberError}</p>
      </div>
      {type == "team" ? (
        <div className="data-input-custom">
          <h4>Position</h4>
          <input
            type="text"
            name="teamPartnerPosition"
            value={teamPartnerPosition}
            onChange={(e) => setTeamPartnerPosition(e.target.value)}
            id="teamPartnerPosition"
          />
          <p>{teamPartnerPositionError}</p>
        </div>
      ) : null}

      <div className="data-input-custom">
        <h4>{type == "team" ? "Image" : "Icon"}</h4>
        <div className="icon-input-custom">
          <input
            type="file"
            name="teamPartnerImage"
            id="teamPartnerImage"
            onChange={(e) => handleDataUpload(e, "teamPartner")}
            accept="image/*"
          />
          <div className="s-icon">
            <img
              width={80}
              // height={80}
              // style={{ borderRadius: "100%" }}
              src={teamPartnerImageUrl}
            />
          </div>
        </div>
      </div>
      <div className="data-input-custom">
        <h4>FB</h4>
        <input
          type="text"
          name="teamPartnerFB"
          value={teamPartnerFB}
          onChange={(e) => setTeamPartnerFB(e.target.value)}
          id="teamPartnerFB"
        />
        <p>{teamPartnerFBError}</p>
      </div>
      <div className="data-input-custom">
        <h4>TW</h4>
        <input
          type="text"
          name="teamPartnerTW"
          value={teamPartnerTW}
          onChange={(e) => setTeamPartnerTW(e.target.value)}
          id="teamPartnerTW"
        />
        <p>{teamPartnerTWError}</p>
      </div>
      <div className="data-input-custom">
        <h4>IN</h4>
        <input
          type="text"
          name="teamPartnerIN"
          value={teamPartnerIN}
          onChange={(e) => setTeamPartnerIN(e.target.value)}
          id="teamPartnerIN"
        />
        <p>{teamPartnerINError}</p>
      </div>
      <div className="data-input-custom">
        <h4>Website</h4>
        <input
          type="text"
          name="teamPartnerWeb"
          value={teamPartnerWeb}
          onChange={(e) => setTeamPartnerWeb(e.target.value)}
          id="teamPartnerWeb"
        />
        <p>{teamPartnerWebError}</p>
      </div>
    </section>
  );
}

export default TeamPartner;
