import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

export default function PaginationArrow({
  direction,
  offset,
  setOffset,
  page,
  setPage,
}) {
  const setArrowDirection = (direction) => {
    if (direction == "prev") return "/images/icon/pagination-arrowp.svg";
    else return "/images/icon/pagination-arrown.svg";
  };

  const handleClick = () => {
    if (direction == "prev") setOffset(offset - 1);
    else setOffset(offset + 1);
  };

  useEffect(() => setPage(offset * 4 + 1), [offset]);

  return (
    <>
      <li className="page-item">
        <Link
          className="page-link hover-btn"
          onClick={handleClick}
          aria-label={direction}
        >
          <span>
            <img
              src={process.env.PUBLIC_URL + setArrowDirection(direction)}
              alt="images"
            />
          </span>
        </Link>
      </li>
    </>
  );
}
