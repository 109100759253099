import React from "react";
import FeatureItem from "./FeatureItem";

export default function ProductFeatures({ data }) {
  const size = 12 / data?.length;
  return (
    <>
      <div className="row g-3">
        {data?.map((item, index) => {
          return <FeatureItem data={item} key={index} size={size} />;
        })}
      </div>
    </>
  );
}
