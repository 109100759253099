import React from "react";
import SocialLinkWithIcon from "./SocialLinkWithIcon";

export default function HeaderSocialLinkedIn() {
  return (
    <>
      <ul className="header-social">
        <SocialLinkWithIcon
          text="Linked "
          icon="bi bi-linkedin"
          link="https://www.linkedin.com/company/com-iot-technologies/"
        />
        <SocialLinkWithIcon
          text="YouTube "
          icon="bi bi-youtube"
          link="https://www.youtube.com/channel/UCvSXiF4O9QbN4mqDWQCftxQ/featured"
        />
        <SocialLinkWithIcon
          text="Twitter "
          icon="bi bi-twitter"
          link="https://twitter.com/comIoTtech"
        />
      </ul>
    </>
  );
}
