import validator from "validator";
import axios from "axios";

const endPoint = process.env.REACT_APP_END_POINT + "/api";

export const sendEmail = async (data) => {
  try {
    const validators = [];

    if (!data?.email || !validator.isEmail(data?.email))
      validators.push({
        param: "email",
        msg: "Please enter a valid email!",
      });
    if (
      !data?.subject ||
      !validator.isLength(data?.subject, { min: 3, max: 60 })
    )
      validators.push({
        param: "subject",
        msg: "Subject must be between 3 and 60 chars!",
      });
    if (!data?.message || !validator.isLength(data?.message, { min: 5 }))
      validators.push({
        param: "message",
        msg: "Message must be more than 5 chars long!",
      });
    if (validators.length > 0)
      return {
        success: false,
        message: "Validation error!",
        validators,
      };
    const res = await axios.post(`${endPoint}/send-mail`, data);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
