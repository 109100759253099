import React from "react";
import NewsInfo from "./NewsInfo";
import Media from "../../common/sections/Media";
import MainSection from "../../common/sections/MainSection";
import Sections from "../../common/sections/Sections";

function BlogMainPost({ data }) {
  return (
    <>
      <div className="blog-post-img">
        <Media mediaUrl={data?.mainMediaURL} />
      </div>
      <NewsInfo by={data?.author} date={data?.date} />

      <MainSection
        title={data?.mainTitle}
        text={data?.mainSection}
        type="news"
      />

      <Sections sectionsData={data?.sections} type="news" />
    </>
  );
}

export default BlogMainPost;
