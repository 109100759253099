import React from "react";
import { Link } from "react-router-dom";
import { isAdmin } from "../../../../utils/helpers";
import EditDeleteBtn from "../CommonUtils/EditDeleteBtn";
import SocialMediaLinks from "../CommonUtils/SocialMediaLinks";

const endPoint = process.env.REACT_APP_END_POINT;

function TeamPartnerCard(props) {
  const { showModal, wowDelay, colWidth, teamOrPartner, data } = props;
  return (
    <div
      className={`${colWidth} col-6 order-1 wow animate fadeInDown`}
      data-wow-duration="1.5s"
      data-wow-delay={`${wowDelay}s`}
    >
      <div className="team-item d-flex">
        <img src={endPoint + data?.imageURL} alt="images" />
        <div className="team-overlay">
          <div className="overlay-content hover-btn">
            <span />
            <a rel="noopener noreferrer" href={data?.webLink} target="_blank">
              <h4>{data?.name}</h4>
            </a>

            <p> {data?.position} </p>
            <div>
              <SocialMediaLinks fbLink={data?.fbLink} twLink={data?.twLink} inLink={data?.inLink} cssClassName="" />
            </div>
            <div>
              <EditDeleteBtn showModal={showModal} type={teamOrPartner} data={data} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TeamPartnerCard;
