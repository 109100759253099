import React, { useState, useEffect } from "react";
import FooterLink from "./FooterLink";
import { getAllProducts } from "../../../APIs/productAPIs";

export default function FooterProduct() {
  const [allProducts, setAllProducts] = useState();
  useEffect(async () => {
    const data = await getAllProducts(5);
    setAllProducts(data?.products);
  }, []);

  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-sm-start">
        <div className="footer-item text-sm-start">
          <h5 className="footer-title">Our Solutions</h5>
          <ul className="footer-link">
            {allProducts?.map((product, index) => {
              return (
                <FooterLink
                  link={`/solutions/${product?.title.replaceAll(" ", "_")}`}
                  text={`${product?.title}`}
                  key={index}
                />
              );
            })}
          </ul>
        </div>
      </div>
    </>
  );
}
