import React, { useState, useEffect, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import BlogDetailsWrap from "./BlogDetailsWrap";

import { getNewsById } from "../../../APIs/newsAPIs";
import { getAllNews } from "../../../APIs/newsAPIs";
import { getPrevNextNews } from "../../../APIs/newsAPIs";

import { getBlogById } from "../../../APIs/blogsAPIs";
import { getAllBlogs } from "../../../APIs/blogsAPIs";
import { getPrevNextBlogs } from "../../../APIs/blogsAPIs";
import Spinner from "../../common/Spinner";
import { Context } from "../../App";

function BlogDetailsPage() {
  const { reloadAnimation } = useContext(Context);
  const [newsBlogDetailsData, setNewsBlogDetailsData] = useState();
  const [recentPosts, setRecentPosts] = useState();
  const [prevPost, setPrevPost] = useState();
  const [nextPost, setNextPost] = useState();
  const [loading, setLoading] = useState(true);
  let { id } = useParams();
  let { pathname } = useLocation();
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  useEffect(async () => {
    scrollTop();
    let data = "";
    let recentData = "";
    let prevNextData = "";
    if (pathname.includes("news")) {
      data = await getNewsById(id);
      recentData = await getAllNews(6);
      prevNextData = await getPrevNextNews(data?.news?.date);
      setNewsBlogDetailsData(data?.news);
      setPrevPost(prevNextData?.prev);
      setNextPost(prevNextData?.next);
      setRecentPosts(recentData?.news);
    }

    if (pathname.includes("blogs")) {
      data = await getBlogById(id);
      recentData = await getAllBlogs(6);
      prevNextData = await getPrevNextBlogs(data?.blog?.date);
      setNewsBlogDetailsData(data?.blog);
      setPrevPost(prevNextData?.prev);
      setNextPost(prevNextData?.next);
      setRecentPosts(recentData?.blogs);
    }
    if (data && recentData && prevNextData) setLoading(false);
    reloadAnimation();
  }, [pathname]);

  const setName = () => {
    if (pathname.includes("news")) return "News Details";
    if (pathname.includes("blogs")) return "Blog Details";
  };

  return (
    <>
      <Breadcrumb name={setName()} />
      {loading ? (
        <Spinner biggerHeight />
      ) : (
        <BlogDetailsWrap
          data={newsBlogDetailsData}
          recentData={recentPosts}
          nextPost={nextPost}
          prevPost={prevPost}
          setLoading={setLoading}
        />
      )}
    </>
  );
}

export default BlogDetailsPage;
