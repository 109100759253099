import React, { useState, useEffect } from "react";
import { isAdmin } from "../../../utils/helpers";
import ProductCard from "./ProductCard";
import AddOrAllSection from "../home/CommonUtils/AddOrAllSection";
import Spinner from "../../common/Spinner";

function ProductWrap({ data, position, header }) {
  const isAdminUser = isAdmin();

  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });
  return (
    <>
      <div className="latest-work pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div className="col-md-8 text-center">
              <div
                className="section-title"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <h2>{header}</h2>
              </div>
            </div>
          </div>
          <div className="row g-4 d-flex justify-content-center">
            {data ? (
              <>
                {data?.map((product, index) => {
                  return (
                    <ProductCard
                      data={product}
                      key={index}
                      wowDelay={0.5 + 0.1 * index}
                    />
                  );
                })}

                {isAdminUser && (
                  <div className="col-lg-4 col-md-6 col-sm-8 order-1 wow animate fadeInDown">
                    <div
                      className="section-card l-work-card wow animate fadeInDown"
                      data-wow-duration="1.5s"
                      data-wow-delay="0.5s"
                    >
                      <AddOrAllSection link="solutions" type="product" />
                    </div>
                  </div>
                )}
              </>
            ) : (
              <Spinner />
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default ProductWrap;
