import React, { useState, createContext, useEffect } from "react";
import { useHistory } from "react-router-dom";
import Footer from "./common/Footer";
import Header from "./common/Header";
import DataModal from "./page/home/Modals/DataModal";
import * as Socket from "../utils/sockets";
import { locationIncrement } from "../utils/helpers";
import { Helmet } from "react-helmet";

const endPoint = process.env.REACT_APP_END_POINT;

export const Context = createContext({});

const MemoContextProvider = React.memo(Context.Provider);

const HeaderStatic = React.memo(Header);

const FooterStatic = React.memo(Footer);

function Layout({ children }) {
  const [open, setOpen] = useState(false);
  const [type, setType] = useState("");
  const [data, setData] = useState({});
  const [_, setReload] = useState(false);
  const history = useHistory();
  // console.log(history?.location?.pathname);
  useEffect(() => {
    fetch(endPoint + "/alive");
    locationIncrement(history.location.pathname);
    history.listen((location) => {
      if (location.pathname == "/") {
        const header = document.querySelector(".header-section");
        header.classList.remove("sticky");
        window.scrollTo({ top: 0, behavior: "smooth" })
      }
      locationIncrement(location.pathname);
    });
  }, []);

  const reloadAnimation = () => {
    // console.log("reloadding animation");
    setReload(!_);
  };

  const showModal = (type, data) => {
    setType(type);
    setOpen(true);
    setData(data);
  };

  return (
    <>
      {/* {history?.location?.pathname == "/" ? null : <HeaderStatic />} */}
      <Helmet>
        <meta name="description" content="COM-IoT Technologies is an Artificial Intelligence company specialized in smart city solutions." />
        <meta property="og:url" content="https://com-iot.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="COM-IoT Technologies" />
        <meta property="og:description" content="COM-IoT Technologies is an Artificial Intelligence company specialized in smart city solutions." />
        <meta
          property="og:image"
          content="https://com-iot.com/logo.jpg"
        />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:site" content="@com-iot" />
        <meta property="twitter:url" content="https://com-iot.com/" />
        <meta name="twitter:title" content="COM-IoT Technologies" />
        <meta
          name="twitter:description"
          content="COM-IoT Technologies is an Artificial Intelligence company specialized in smart city solutions."
        />
        <meta
          name="twitter:image"
          content="https://com-iot.com/logo.jpg"
        />
        <title>COM-IoT Technologies</title>
      </Helmet>
      <Header />

      <DataModal type={type} open={open} setOpen={setOpen} data={data} />

      <Context.Provider value={{ showModal, reloadAnimation }}>
        {children}
      </Context.Provider>
      {/* {history?.location?.pathname == "/" ? null : <FooterStatic />} */}
      <Footer />
    </>
  );
}

export default Layout;
