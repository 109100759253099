import React from "react";
import { Link } from "react-router-dom";
import EditDeleteBtn from "../home/CommonUtils/EditDeleteBtn";

const endPoint = process.env.REACT_APP_END_POINT;
const publicURL = process.env.PUBLIC_URL;

function ProjectCard({ lgWidth, mdWidth, data }) {
  return (
    <>
      <div
        className={`
        col-lg-${lgWidth || 4} 
        col-md-${mdWidth || 6}  
        col-sm-8 order-1 wow animate fadeInDown
        `}
        data-wow-duration="1.5s"
        data-wow-delay="0.6s"
      >
        <div className="l-work-item">
          <img
            src={endPoint + data?.wallImageURL}
            alt="images"
            style={{
              height: lgWidth ? 425 : 320,
            }}
          />
          <div className="work-overlay">
            <div className="work-title hover-btn">
              <span> </span>
              <i className="bi bi-arrow-90deg-right"></i>
              <h3>
                <Link
                  to={`/projects/${data?._id}`}
                  onClick={() =>
                    window.scrollTo({ top: 0, behavior: "smooth" })
                  }
                >
                  {data?.title}
                </Link>
              </h3>
            </div>
            <EditDeleteBtn type="project" data={data} />
          </div>
        </div>
      </div>
    </>
  );
}

export default ProjectCard;
