import React from "react";
import VideoPlayer from "../VideoPlayer";

const endPoint = process.env.REACT_APP_END_POINT;
const publicURL = process.env.PUBLIC_URL;

export default function Media({ mediaUrl }) {
  const setMediaType = (url) => {
    if (url?.includes("/images/")) {
      return "image";
    }
  };

  return (
    <div>
      {mediaUrl?.includes("images") ? <img src={endPoint + mediaUrl} alt="images" /> : <VideoPlayer url={mediaUrl} />}
    </div>
  );
}
