import React from "react";
import Media from "./Media";
import NewsInfo from "../../page/blogDetails/NewsInfo";

export default function MainSection(props) {
  const { title, text, type } = props;
  return (
    <>
      {type == "news" ? (
        <>
          {title && <h2 className="post-title">{title}</h2>}
          <div
            className="styled-text para-style mb-30"
            dangerouslySetInnerHTML={{
              __html: text.replace(/\n/g, "<br>"),
            }}
          />
          {/* <p className="para-style mb-30">{text}</p> */}
        </>
      ) : (
        <>
          {title && <h3>{title}</h3>}
          <div
            className="styled-text para"
            dangerouslySetInnerHTML={{
              __html: text.replace(/\n/g, "<br>"),
            }}
          />
          {/* <p className="para">{text}</p> */}
        </>
      )}
    </>
  );
}
