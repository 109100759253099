import React from "react";
import FooterLink from "./FooterLink";

export default function ExploreOn() {
  return (
    <>
      <div className="col-lg-4 col-md-6 col-sm-6 d-flex justify-content-sm-start">
        <div className="footer-item text-sm-start">
          <h5 className="footer-title">Explore</h5>
          <ul className="footer-link">
            <FooterLink link="about-us" text="About Us" />
            <FooterLink link="partners" text="Partners" />
            <FooterLink link="news" text="News" />
            <FooterLink link="blogs" text="Blog" />
            <FooterLink link="customer-testimonies" text="Customer Testimonies" />
          </ul>
        </div>
      </div>
    </>
  );
}
