import React from "react";
import { Link } from "react-router-dom";
import EditDeleteBtn from "../home/CommonUtils/EditDeleteBtn";
import ReadMoreBtn from "../home/NewsUtils/ReadMoreBtn";

const endPoint = process.env.REACT_APP_END_POINT;
function BlogCard({ data, link, type }) {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <>
            <div
                className="eg-card-wrap wow animate fadeInDown"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
            >
                <div className="eg-card blog-item-1 d-flex justify-content-sm-start justify-content-center align-items-center flex-sm-nowrap flex-wrap">
                    <div className="blog-img" style={{ maxHeight: "200px" }}>
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/${link}/${data?._id}`}
                        >
                            <img
                                src={
                                    data?.wallImageURL
                                        ? endPoint + data?.wallImageURL
                                        : "images/icon/no_img.png"
                                }
                                alt="images"
                            />
                        </Link>
                    </div>
                    <div className="blog-content text-sm-start text-center">
                        {data?.author && <h6>Posted by {data?.author}</h6>}
                        <Link
                            onClick={scrollTop}
                            to={`${process.env.PUBLIC_URL}/${link}/${data?._id}`}
                        >
                            <h4>{data?.title}</h4>
                        </Link>
                        <ReadMoreBtn link={link} id={data?._id} />
                        <EditDeleteBtn type={type} color="white" data={data} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogCard;
