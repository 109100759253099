import React from "react";

const endPoint = process.env.REACT_APP_END_POINT;

export default function FeatureItem({ data, size }) {
  return (
    <>
      <div className={`col-lg-${size} col-md-6 col-sm-${size}`}>
        <div className="work-pro-item">
          <img
            // src={process.env.PUBLIC_URL + "/images/icon/workp1.svg"}
            src={endPoint + data?.iconURL}
            alt="images"
            width={110}
            height={110}
          />
          <h4>{data?.text}</h4>
        </div>
      </div>
    </>
  );
}
