import React from "react";
import { Link } from "react-router-dom";

function NextAndPrevPost({ nextPost, prevPost, setLoading }) {
  const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

  const handleClick = () => {
    scrollTop();
    setLoading(true);
  };

  return (
    <>
      <div className="next-prev-post">
        <div className="row g-3">
          <div className="col-md-6 text-md-start text-center">
            {prevPost?.title && (
              <div className="post-arrow">
                <Link to={prevPost?._id} onClick={handleClick}>
                  <h6>Previous Post</h6>
                  <h5>{prevPost?.title}</h5>
                </Link>
              </div>
            )}
          </div>
          <div className="col-md-6 text-md-end text-center">
            {nextPost?.title && (
              <div className="post-arrow">
                <Link to={nextPost?._id} onClick={handleClick}>
                  <h6>Next Post</h6>
                  <h5>{nextPost?.title}</h5>
                </Link>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}

export default NextAndPrevPost;
