import axios from "axios";
const token = localStorage.getItem("token");
const endPoint = process.env.REACT_APP_END_POINT + "/api";

const auth = {
  headers: { Authorization: `Bearer ${token}` },
};

export const getProjectById = async (id) => {
  try {
    const res = await axios.get(`${endPoint}/projects/${id}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const getAllProjects = async (limit) => {
  try {
    let limitURL = "";
    if (limit) limitURL = `?limit=${limit}`;
    const res = await axios.get(`${endPoint}/projects${limitURL}`);
    return res?.data;
  } catch (err) {
    // console.log(err.response.data);
  }
};

export const addOrUpdateProject = async (formData, id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    let res;
    if (id) res = await axios.put(`${endPoint}/projects/${id}`, formData, auth);
    else res = await axios.post(`${endPoint}/projects`, formData, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
        validators: err?.response?.data?.validators,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};

export const deleteProject = async (id) => {
  try {
    const token = localStorage.getItem("token");
    auth.headers = { Authorization: `Bearer ${token}` };
    const res = await axios.delete(`${endPoint}/projects/${id}`, auth);
    return res?.data;
  } catch (err) {
    if (err?.response?.data?.message)
      return {
        success: false,
        message: err?.response?.data?.message,
      };
    return {
      success: false,
      message: "something went wrong!",
    };
  }
};
