import React, { useContext, useEffect, useState } from "react";
import { getAllProducts } from "../../../APIs/productAPIs";
import ComiotSolutionsImg from "../../../assets/images/bg/comiot-solutions.jpg";
import { Context } from "../../App";
import Breadcrumb from "../../common/Breadcrumb";
import ProductWrap from "./ProductWrap";

function ProductPage() {
    const { reloadAnimation } = useContext(Context);
    const [allMobilityProducts, setAllMobilityProducts] = useState();
    const [allSecurityProducts, setAllSecurityProducts] = useState();

    const scrollTop = () => window.scrollTo({ top: 0, behavior: "smooth" });

    useEffect(async () => {
        scrollTop();
        const securityData = await getAllProducts("", "security");
        const mobilityData = await getAllProducts("", "mobility");
        setAllSecurityProducts(securityData?.products);
        setAllMobilityProducts(mobilityData?.products);
        reloadAnimation();
    }, []);

    return (
        <>
            <Breadcrumb name="Our Solutions" />
            <div className="latest-work pt-120">
                <div className="container">
                    <div className="row d-flex justify-content-lg-start justify-content-center">
                        <div className="col-md-8 text-center">
                            <div
                                className="section-title"
                                data-wow-duration="1.5s"
                                data-wow-delay="0.5s"
                            >
                                <h2>COM-IoT 47 IPs</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row g-4 d-flex justify-content-center">
                        <img
                            src={ComiotSolutionsImg}
                            alt="Comiot Solutions"
                            className="solutions-main-img"
                        />
                    </div>
                </div>
                {/* <div className="container">
                <img
                    src={ComiotSolutionsImg}
                    alt="Comiot Solutions"
                    className="solutions-main-img"
                />
            </div> */}
            </div>
            {/* <img
                src={ComiotSolutionsImg}
                alt="Comiot Solutions"
                className="solutions-main-img"
            /> */}
            <ProductWrap
                data={allMobilityProducts}
                // position={{ marginRight: "auto" }}
                header={"Mobility"}
            />
            <ProductWrap
                data={allSecurityProducts}
                // position={{ marginRight: "auto" }}
                header={"Security"}
            />
            {/* <JoinUs padding="pt-120" /> */}
            {/* <Sponsor /> */}
        </>
    );
}

export default ProductPage;
