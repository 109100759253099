import React from "react";
import { useLocation } from "react-router-dom";
import Breadcrumb from "../../common/Breadcrumb";
import BlogGridWrap from "./BlogGridWrap";
import NewsGridWrap from "./NewsGridWrap";

function BlogGridPage() {
  let { pathname } = useLocation();

  const setName = () => {
    if (pathname.includes("news")) return "Our News";
    if (pathname.includes("blogs")) return "Our Blog";
  };

  return (
    <>
      <Breadcrumb name={setName()} />
      {pathname.includes("news") ? <NewsGridWrap /> : <BlogGridWrap />}
    </>
  );
}

export default BlogGridPage;
