import React from "react";
import NewsCard from "./NewsUtils/NewsCard";
import AddOrAllSection from "./CommonUtils/AddOrAllSection";

function BlogArea({ data }) {
  return (
    <>
      <div className="blog-section pt-120">
        <div className="container">
          <div className="row d-flex justify-content-lg-start justify-content-center">
            <div
              className="col-md-8 text-center wow animate fadeInDown"
              data-wow-duration="1.5s"
              data-wow-delay="0.5s"
            >
              <div className="section-title pt-0">
                <h2>Our Blog</h2>
              </div>
            </div>
          </div>
          <div className="row d-flex justify-content-center g-4">
            <div className="col-lg-8">
              {data?.map((blog, index) => {
                return (
                  <NewsCard
                    data={blog}
                    key={index}
                    type={"blogs"}
                    link="blogs"
                  />
                );
              })}
            </div>
            <div className="col-lg-4 col-md-8 col-sm-8">
              <div
                className="section-card blog-section-card wow animate fadeInUp"
                data-wow-duration="1.5s"
                data-wow-delay="0.5s"
              >
                <AddOrAllSection link="blogs" type="blogs" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogArea;
