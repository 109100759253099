import React from "react";

export default function Comment({ commentData, text, author, type }) {
  const setBackground = (type) => {
    if (type == "news") return "";
    else return "rgba(1, 95, 139, 0.4)";
  };
  return (
    <div className="blog-post-area">
      <div
        className="blockquote"
        // style={{ background: "##015f8bab" }}
        style={{ background: setBackground(type) }}
      >
        <h5>“{commentData?.text}”</h5>
        <h4 style={{ margin: 0 }}>-{commentData?.author}-</h4>
        <img
          src={process.env.PUBLIC_URL + "/images/icon/quote-icon.svg"}
          className="quote-icon"
          alt="images"
        />
      </div>
    </div>
  );
}
