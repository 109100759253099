import React from "react";
import Spinner from "../common/Spinner";

function Preloader() {
  return (
    <>
      <div className="our-preloader">
        <img src={process.env.PUBLIC_URL + "/logo-1.png"} alt="images" />
        <Spinner />
      </div>
    </>
  );
}

export default Preloader;
